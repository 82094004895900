<template>
  <localized-link :to="{ path: path, hash: hash }">
    <div class="relative overflow-hidden rounded-xl">
      <div :style="{ 'background-image': `url(${img})` }" class="h-48 sm:h-64 md:h-72 lg:h-96 bg-cover transform hover:scale-110 transition-transform duration-300" :class="imgAlign"></div>
      <div class="absolute bottom-0 flex flex-col justify-center bg-gray-500 bg-opacity-30 md:bg-opacity-10 text-center p-4 w-full h-full md:h-32 pointer-events-none md:backdrop-filter md:backdrop-blur-xs">
        <h3 class="mb-2">{{ title }}</h3>
        <div class="text-gray-200 text-base sm:text-lg">
          <slot></slot>
        </div>
      </div>
    </div>
  </localized-link>
</template>

<script>
export default {
  name: 'HoverImage',
  props: {
    img: String,
    title: String,
    description: String,
    path: String,
    hash: String,
    imgAlign: {
      type: String,
      default: 'bg-center'
    }
  }
}
</script>
