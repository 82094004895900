<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
  </Head>

  <BlackWhiteTemplate :title="$t('emailVerification')">
    <div class="container py-8">
      <p v-show="message">{{ message }}</p>
      <div v-show="errorMessage" class="text-red flex items-start">
        <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'VerifyEmail',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    BlackWhiteTemplate,
    ExclamationIcon
  },
  data () {
    return {
      message: null,
      errorMessage: null
    }
  },
  methods: {
    verifyEmail () {
      this.$axios.post('/auth/verify-email', {
        token: this.$route.query.token
      })
        .then(res => {
          this.message = this.$t('emailVerified')
          this.errorMessage = null
        })
        .catch(err => {
          this.errorMessage = this.$axiosErrorHandler(err)
          this.message = null
        })
    }
  },
  mounted () {
    this.verifyEmail()
  }
}
</script>

<i18n>
{
  "en": {
    "head": {
      "title": "E-mail verification - Yn'forma",
    },
    "emailVerification": "E-mail verification",
    "emailVerified": "Your e-mail address has been verified! You can now log in and use your account."
  },
  "nl": {
    "head": {
      "title": "E-mailverificatie - Yn'forma",
    },
    "emailVerification": "E-mailverificatie",
    "emailVerified": "Uw e-mailadres werd bevestigd. U kan nu inloggen en uw account gebruiken."
  },
  "fr": {
    "head": {
      "title": "Vérification d'adresse e-mail - Yn'forma",
    },
    "emailVerification": "Vérification d'adresse e-mail",
    "emailVerified": "Votre adresse e-mail a été confirmée. Vous pouvez maintenant vous connecter et utiliser votre compte."
  }
}
</i18n>
