<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="og:title" :content="title">
    <!-- TODO add other tags -->
  </Head>

  <div class="flex-grow">
    <Loading :show="loading" :label="$t('loadingStatus')"/>

    <div v-if="order">
      <div v-if="order.status === 'paymentFailed'">
        <h2>{{ $t('paymentCanceledTitle') }}</h2>
        <p>{{ $t('paymentCanceled') }}</p>

        <localized-link :to="{ path: `/bestellen` }" class="mt-6">
          <Button>
            {{ $t('toCheckout') }}
            <template v-slot:iconRight>
              <ChevronRightIcon/>
            </template>
          </Button>
        </localized-link>
      </div>

      <div v-else>
        <h2>{{ $t('orderPlacedTitle') }}</h2>
        <p>{{ $t('orderPlaced') }}</p>

        <localized-link :to="{ path: `/` }" class="mt-6">
          <Button>
            {{ $t('toHome') }}
            <template v-slot:iconRight>
              <ChevronRightIcon/>
            </template>
          </Button>
        </localized-link>
      </div>
    </div>

    <div v-if="errorMessage" class="text-red">{{ errorMessage }}</div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'
import Loading from '@/components/Loading'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import Button from '@/components/Button'

export default {
  name: 'OrderProcessed',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    Loading,
    ChevronRightIcon,
    Button
  },
  data () {
    return {
      loading: true,
      retryCounter: 0,
      retryInterval: 2000,
      retryMax: 30,
      order: null,
      errorMessage: null,
      title: 'Yn\'forma'
    }
  },
  methods: {
    getOrder () {
      this.loading = true
      this.$axios.get(`/orders?orderNumber=${this.$route.query['order-number']}`)
        .then(res => {
          const order = res.data.results[0]

          this.errorMessage = null
          
          if (order.status === 'pending') {
            // Payment not completed yet, wait and retry.
            this.retryCounter++
            if (this.retryCounter < this.retryMax) {
              setTimeout(this.getOrder, this.retryInterval)
            } else {
              this.loading = false
              this.errorMessage = this.$t('stillPending')
            }
          } else {
            this.order = order
            this.loading = false

            // If paid, clear shopping cart.
            if (order.status !== 'paymentFailed') {
              this.title = this.$t('head.titlePlaced')
              this.$store.commit('clearShoppingCart')
              this.$store.commit('clearCheckoutDetailsShoppingCart')
            } else {
              this.title = this.$t('head.titleCanceled')
            }
          }
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    }
  },
  mounted () {
    this.title = this.$t('head.titleLoading')
    this.getOrder()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "titleLoading": "Betaling nakijken - Yn'forma",
      "titlePlaced": "Bestelling geplaatst - Yn'forma",
      "titleCanceled": "Betaling afgebroken - Yn'forma"
    },
    "loadingStatus": "Betaling nakijken",
    "orderPlacedTitle": "Bestelling geplaatst",
    "orderPlaced": "Wij danken u voor uw bestelling! U wordt op de hoogte gehouden over de status ervan.",
    "toHome": "Terug naar de startpagina",
    "paymentCanceledTitle": "Betaling afgebroken",
    "paymentCanceled": "Uw betaling werd afgebroken en uw bestelling is bijgevolg niet geplaatst. Gelieve opnieuw te proberen of ons rechtstreeks te contacteren om de bestelling te plaatsen.",
    "toCheckout": "Terug naar uw bestelling",
    "stillPending": "De betalingsstatus is nog niet beschikbaar. Vernieuw deze pagina om opnieuw te proberen of contacteer ons om na te kijken of uw bestelling geplaatst is."
  }
}
</i18n>
