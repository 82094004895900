import createDOMPurify from 'dompurify'

// Normal: retain only specific tags.
const purify = createDOMPurify()
const sanitizeDirective = (el, binding) => {
  const sanitizeConfig = {
    ALLOWED_TAGS: ['b', 'i', 'strong', 'p', 'br', 'ul', 'ol', 'li', 'table', 'tr', 'td', 'th', 'thead', 'tbody', 'tfoot']
  }

  el.innerHTML = purify.sanitize(binding.value, sanitizeConfig)
}

// Flat: remove all styling, remove tables. Replace <p> by \r\n.
const flatPurify = createDOMPurify()
flatPurify.addHook('afterSanitizeElements', function (node) {
  if (!node.tagName) {
    return
  }

  if (['p', 'br', 'li'].includes(node.tagName.toLowerCase())) {
    node.outerHTML = node.innerHTML + '\r\n'
  }

  if (node.tagName.toLowerCase() === 'table') {
    node.remove()
  }
})

const flatSanitizeDirective = (el, binding) => {
  const sanitizeConfig = {
    ALLOWED_TAGS: ['p', 'br', 'li', 'table']
  }

  el.innerHTML = flatPurify.sanitize(binding.value, sanitizeConfig)
}

export default {
  install: app => {
    app.directive('html-sanitized', sanitizeDirective)
    app.directive('html-sanitized-flat', flatSanitizeDirective)
  }
}
