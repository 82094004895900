<template>
  <div v-if="type === 'radio'">
    <div v-for="(v, index) in radioValues" :key="index">
      <VField :name="name" type="radio" :value="v.value" :id="v.value" :validateOnBlur="false" :validateOnChange="true" :validateOnInput="false"/>
      <label :for="v.value" class="ml-2">{{ v.display }}</label>
    </div>
    <ErrorMessage :name="name" v-slot="{ message }">
      <span class="text-red flex items-center mt-1" v-show="message">
        <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
        <span>{{ message }}</span>
      </span>
    </ErrorMessage>
  </div>

  <div v-else>
    <VField :name="name" v-slot="{ field, resetField, errorMessage }" :validateOnBlur="false" :validateOnChange="true" :validateOnInput="false">      
      <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 items-center">
        <div class="flex">
          <div class="flex-grow ring-1 ring-gray-400 rounded-md flex flex-col" :class="{ 'text-red ring-2 ring-red': errorMessage, 'bg-gray-200': disabled }">
            <label :for="name" class="pt-1 px-3 text-gray-400 text-xs font-bold uppercase" :class="{ 'text-red': errorMessage, 'text-gray-500': !disabled }">{{ label }}</label>
            <textarea v-if="type === 'textarea'" cols="1" :rows="rows" :disabled="disabled" :placeholder="label" :id="name" :autocomplete="autocomplete" v-bind="field" @change="removeEmpty(resetField, $event)" class="w-full rounded-md pb-1 px-3 outline-none bg-transparent"></textarea>
            <input v-else :disabled="disabled" :type="type" :placeholder="label" :id="name" :autocomplete="autocomplete" v-bind="field" @change="removeEmpty(resetField, $event)" class="w-full rounded-md pb-1 px-3 outline-none bg-transparent"/>
          </div>
          <slot name="right" class="flex-shrink-0"></slot>
        </div>
        <span class="text-red flex items-center mt-1 lg:mt-0 lg:ml-3 xl:col-span-2" v-show="errorMessage">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </span>
      </div>
    </VField>
  </div>
</template>

<script>
import { Field as VField, ErrorMessage } from 'vee-validate'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'Field',
  components: {
    VField,
    ErrorMessage,
    ExclamationIcon,
  },
  props: {
    name: String,
    label: String,
    type: String,
    default: Object,
    autocomplete: {
      type: String,
      default: "nope" // "off" doesn't work on Chrome. This workaround disables autofill but still leaves suggestions. Not ideal. Best solution wouldn't suggest anything.
    },
    disabled: {
      type: Boolean,
      value: false
    },
    rows: {
      type: Number,
      default: 10
    },
    radioValues: Array
  },
  methods: {
    removeEmpty (reset, event) {
      if (typeof event.target.value === 'string' && event.target.value.trim() === '') {
        // If no default is provided, default is undefined, which is precisely what we would want: this input's key will be removed from the object.
        // If a value is provided, then that value is set.
        reset({ value: this.default })
      }
    }
  }
}
</script>

<style scoped>
/* Remove blue background on input fields when Chrome autofills. */
input:-webkit-autofill, input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s !important;
}
</style>