import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from 'vue-lang-router'
import { createHead } from '@vueuse/head'
import { axios, axiosErrorHandler } from '@/utils/axios'
import vHtmlSanitized from './utils/vHtmlSanitized'
import tailwind from './tailwind'
import aos from 'aos'
import 'aos/dist/aos.css'

const app = createApp(App)
const head = createHead()

const aosConfig = {
  once: true,
  anchorPlacement: 'top-bottom' // TODO: doesn't work. Figure out why. In the meantime, specify on every element.
}
aos.init(aosConfig)

app.provide('$axios', axios)
app.provide('$axiosErrorHandler', axiosErrorHandler)
app.provide('$aosRefresh', aos.refresh)

app.use(store)
  .use(router)
  .use(i18n)
  .use(vHtmlSanitized)
  .use(tailwind)
  .use(head)
  .mount('#app')
