<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
    <!-- TODO add other tags -->
  </Head>

  <div class="flex-grow">
    <h2>{{ $t('buyVoucher') }}</h2>

    <Loading :show="loading" :label="$t('loadingSettings')"/>
    
    <div v-if="!loading && !errorMessage">
      <p>{{ $t('buyVoucherIntro') }}</p>
      <Form @submit="order" :validation-schema="voucherSchema" :initial-values="voucherInitial" v-slot="{ isSubmitting }">
        <h3>{{ $t('price') }}</h3>
        <Field name="price" type="number" :label="$t('price')"/>
        
        <h3>{{ $t('contactDetails') }}</h3>
        <p>{{ $t('contactDetailsIntro') }}</p>
        <Field name="contactDetails.firstName" type="text" :label="$t('firstName')" autocomplete="given-name" class="mb-4"/>
        <Field name="contactDetails.lastName" type="text" :label="$t('lastName')" autocomplete="family-name" class="mb-4"/>
        <Field name="contactDetails.email" type="text" :label="$t('email')" autocomplete="email" class="mb-4"/>
        <Field name="contactDetails.phoneNumber" type="text" :label="$t('phoneNumber')" autocomplete="tel" class="mb-4"/>

        <h3>{{ $t('paymentMethod') }}</h3>
        <Field name="paymentMethod" type="radio" :radioValues="paymentMethodRadioValues" class="mb-4"/>

        <div v-show="orderErrorMessage" class="mb-4 text-red flex items-start">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ orderErrorMessage }}</span>
        </div>

        <Button type="submit" :disabled="isSubmitting">{{ $t('pay') }}</Button>
      </Form>
    </div>

    <div v-show="errorMessage" class="mb-4 text-red flex items-start">
      <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'
import { ExclamationIcon } from '@heroicons/vue/solid'
import { Form } from 'vee-validate'
import '@/validations'
import * as yup from 'yup'
import Field from '@/components/Field'
import Loading from '@/components/Loading'
import Button from '@/components/Button'

export default {
  name: 'Voucher',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    ExclamationIcon,
    Form,
    Field,
    Loading,
    Button
  },
  data () {
    return {
      loading: true,
      errorMessage: null,
      maxVoucherPrice: null,
      paymentMethodRadioValues: [{
        value: 'bancontact',
        display: this.$t('bancontact')
      }, {
        value: 'creditcard',
        display: this.$t('creditCard')
      }],
      orderErrorMessage: null
    }
  },
  computed : {
    voucherSchema () {
      return yup.object({
        contactDetails: yup.object({
          firstName: yup.string()
            .required(this.$t('validation.firstName.required')),
          lastName: yup.string()
            .required(this.$t('validation.lastName.required')),
          email: yup.string()
            .required(this.$t('validation.email.required'))
            .email(this.$t('validation.email.email')),
          phoneNumber: yup.string()
            .required(this.$t('validation.phoneNumber.required'))
            .phoneNumber(this.$t('validation.phoneNumber.format'))
        }),
        price: yup.number()
          .typeError(this.$t('validation.price.type'))
          .required(this.$t('validation.price.required'))
          .maxDecimalPlaces(2, this.$t('validation.price.maxDecimalPlaces', { max: 2 }))
          .min(10, this.$t('validation.price.min', { min: 10 }))
          .max(this.maxVoucherPrice, this.$t('validation.price.max', { max: this.maxVoucherPrice.toFixed(2).replace(/[.,]0+$/, "") })),
        paymentMethod: yup.string()
          .required(this.$t('validation.paymentMethod.required'))
      })
    },
    voucherInitial () {
      return {
        ...this.$store.getters.getCheckoutDetailsVoucher && ({
          price: this.$store.getters.getCheckoutDetailsVoucher.price,
          contactDetails: this.$store.getters.getCheckoutDetailsVoucher.contactDetails,
          paymentMethod: this.$store.getters.getCheckoutDetailsVoucher.paymentMethod,
        })
      }
    }
  },
  methods: {
    getSettings () {
      this.loading = true
      this.$axios.get('/settings')
        .then(res => {
          this.loading = false
          this.errorMessage = null

          this.maxVoucherPrice = res.data.maxVoucherPrice
          this.webshopEnabled = res.data.webshopEnabled

          if (!res.data.webshopEnabled) {
            throw {
              response: {
                data: {
                  message: this.$t('webshopDisabled')
                }
              }
            }
          }
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    },
    order (values, actions) {
      this.$store.commit('setCheckoutDetailsVoucher', values)

      return this.$axios.post('/vouchers', values)
        .then(res => {
          this.orderErrorMessage = null

          if (res.data.checkoutUrl) {
            window.location.replace(res.data.checkoutUrl)
          } else {
            this.orderErrorMessage = this.$t('noCheckoutUrl') // Should not happen.
          }
        })
        .catch(err => {
          this.orderErrorMessage = this.$axiosErrorHandler(err, values, actions)
        })
    }
  },
  mounted () {
    this.getSettings()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Cadeaubon kopen - Yn'forma"
    },
    "loadingSettings": "Informatie laden",
    "buyVoucher": "Koop een cadeaubon",
    "buyVoucherIntro": "Koop hier een Yn'forma cadeaubon en kies zelf voor welk bedrag! De cadeaubon kan zowel voor diensten als in de winkel of webshop gebruikt worden (let op: voor het trimsalon hebben we momenteel een klantenstop). Een welgekomen geschenk voor een vriend of familielid met een viervoeter die een verzorging verdient!",
    "price": "Waarde",
    "contactDetails": "Uw contactgegevens",
    "contactDetailsIntro": "Vul hier uw eigen contactgegevens in. De cadeaubon wordt naar dit e-mailadres verstuurd.",
    "paymentMethod": "Betaalmethode",
    "bancontact": "Bancontact",
    "creditCard": "Kredietkaart",
    "pay": "Betalen",
    "noCheckoutUrl": "Er liep iets mis met het verbinden met het betaalplatform. Uw bestelling is niet geplaatst. Gelieve later opnieuw te proberen of ons te contacteren."
  }
}
</i18n>
