<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
    <!-- TODO add other tags -->
  </Head>

  <div class="flex-grow">
    <div v-if="categories.length > 0" class="flex flex-wrap gap-4 mb-8">
      <button type="button" v-for="category_ in categories" @click="category === category_.id ? category = null : category = category_.id" :key="category_.id" class="bg-gray-200 hover:bg-gray-100 rounded-full px-4 py-1 flex items-center" :class="{ 'pr-3 ring ring-gray-300': category === category_.id }">
        <div>{{ category_.value }}</div>
        <div v-if="category === category_.id">
          <XIcon class="h-5 ml-2"/>
        </div>
      </button>

      <button type="button" @click="category === '' ? category = null : category = ''" class="bg-gray-200 hover:bg-gray-100 rounded-full px-4 py-1 flex items-center" :class="{ 'pr-3 ring ring-gray-300': category === '' }">
        <div>{{ $t('others') }}</div>
        <div v-if="category === ''">
          <XIcon class="h-5 ml-2"/>
        </div>
      </button>
    </div>

    <Loading :show="loading" :showingCallback="function () { products = [] }" :label="$t('loadingProducts')"/>
    
    <p v-if="!loading && !errorMessage && products.length === 0" class="italic">{{ $t('noProducts') }}</p>

    <localized-link v-for="(product, index) in products" :to="{ path: product.isVoucher ? '/cadeaubon' : `/winkel/${product.id}` }" :key="product.id">
      <div class="flex justify-center gap-x-6">
        <div class="h-28 w-28 flex-shrink-0 flex items-center">
          <img v-if="product.image" :src="`/api/files/${product.image.id}/content`" class="h-full object-cover rounded-lg" style="aspect-ratio: 1">
          <img v-else-if="product.isVoucher" :src="require('@/assets/voucher.jpg')" class="h-full mx-auto rounded-lg" style="aspect-ratio: 1">
          <img v-else :src="require('@/assets/no-image.png')" class="h-24 mx-auto rounded-lg" style="aspect-ratio: 1">
        </div>

        <div class="flex-grow flex flex-col md:flex-row">
          <div class="flex-grow md:mr-6">
            <div>{{ product.isVoucher ? $t('voucher') : product.name }}</div>
            <p v-html-sanitized-flat="product.isVoucher ? $t('voucherDescription') : product.description" class="whitespace-pre-wrap text-base text-gray-500" :class="$tailwind.breakpoints.md ? 'line-clamp-3' : 'line-clamp-2'"></p>
          </div>

          <div class="flex md:block items-center justify-between flex-shrink-0 mt-2 md:mt-0">
            <div class="text-right">
              <span v-if="product.isVoucher || (product.variants.length > 1 && product.minPrice !== product.maxPrice)" class="text-sm">{{ $t('from') }}</span>
              €&nbsp;{{ product.minPrice.toFixed(2).replace(/[.,]0+$/, "") }}
            </div>
            <Button :flat="!$tailwind.breakpoints.md" class="ml-4 md:ml-0 md:mt-4">
              {{ $t('more') }}
              <template v-slot:iconRight>
                <ChevronRightIcon/>
              </template>
            </Button>
          </div>
        </div>
      </div>

      <hr v-if="index < products.length - 1" class="md:ml-34 my-6">
    </localized-link>

    <Pagination v-if="pages > 1" v-model="page" :pages="pages" :max-pages="$tailwind.breakpoints.sm ? 5 : 3" class="mt-6 w-min mx-auto"/>

    <div v-show="errorMessage" class="mb-4 text-red flex items-start">
      <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'
import { ExclamationIcon, ChevronRightIcon, XIcon } from '@heroicons/vue/solid'
import Loading from '@/components/Loading'
import Button from '@/components/Button'
import Pagination from '@/components/Pagination'

export default {
  name: 'ProductList',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    ExclamationIcon,
    ChevronRightIcon,
    XIcon,
    Loading,
    Button,
    Pagination
  },
  data () {
    return {
      loading: true,
      products: [],
      page: parseInt(this.$route.query[this.$t('page')]) || 1,
      pages: 1,
      errorMessage: null,
      category: this.$route.query[this.$t('category')] === this.$t('others').toLowerCase() ? '' : this.$route.query[this.$t('category')],
      categories: []
    }
  },
  watch: {
    page (value, old) {
      // Don't change route when the route query has page undefined (which default to 1). Otherwise sometimes
      // a double call to the API happens when changing the undefined route query to 1.
      if (!(value === 1 && this.$route.query[this.$t('page')] === undefined)) {
        this.$router.push({
          query: {
            ...this.$route.query,
            [this.$t('page')]: value
          }
        })
      }
    },
    category (value, old) {
      this.$router.push({
        query: {
          ...this.$route.query,
          [this.$t('page')]: 1,
          [this.$t('category')]: value === null ? undefined : (value === '' ? this.$t('others').toLowerCase() : value)
        }
      })
    },
    $route (newVal, oldVal) {
      if (newVal.name === oldVal.name &&
        (newVal.query[this.$t('page')] !== oldVal.query[this.$t('page')] ||
        newVal.query[this.$t('category')] !== oldVal.query[this.$t('category')])) {
        this.page = parseInt(newVal.query[this.$t('page')]) || 1
        this.category = newVal.query[this.$t('category')] === this.$t('others').toLowerCase() ? '' : newVal.query[this.$t('category')]
        this.getProducts()
      }
    }
  },
  methods: {
    getProducts () {
      this.loading = true
      this.$axios.get('/products', {
        params: {
          ...(this.category !== null) && { category: this.category },
          discontinued: false,
          select: 'name description variants.price variants.discontinued files',
          populate: 'files',
          page: this.page,
          limit: 20,
          sortBy: 'name'
        }
      })
        .then(res => {
          // If page out of bound, change page to last page, which triggers a new API call.
          if (this.page > res.data.totalPages && res.data.totalPages > 0) {
            this.page = res.data.totalPages
            return
          }

          this.errorMessage = null
          this.loading = false
          this.pages = res.data.totalPages
          
          this.products = res.data.results
          for (const product of this.products) {
            // Select first image among files.
            product.image = product.files.find(el => el.mimeType.startsWith('image'))
            
            // Remove discontinued variants.
            product.variants = product.variants.filter(v => !v.discontinued)

            // Find min and max price of variants.
            product.minPrice = Math.min(...product.variants.map(v => v.price))
            product.maxPrice = Math.max(...product.variants.map(v => v.price))
          }

          // If no category selected, or if "others" selected, and on first page, show gift vouchers as a fixed product.
          if (this.page === 1 && (this.$route.query[this.$t('category')] === this.$t('others').toLowerCase() || !this.$route.query[this.$t('category')])) {
            this.products.unshift({
              id: 'voucher',
              isVoucher: true,
              minPrice: 10
            })
          }
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    },
    getCategories () {
      this.$axios.get('/enumerations/productCategories/values', {
        params: {
          sortBy: 'value',
          limit: 999
        }
      })
        .then(res => {
          this.categories = res.data.results
        })
        .catch(err => {
          this.$store.commit('setErrorMessage', this.$axiosErrorHandler(err))
        })
    }
  },
  mounted () {
    this.getCategories()
    this.getProducts()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Winkel - Yn'forma"
    },
    "loadingProducts": "Producten laden",
    "noProducts": "Momenteel zijn er geen producten in het aanbod.",
    "from": "v.a.",
    "others": "Andere",
    "page": "pagina",
    "category": "categorie",
    "voucher": "Cadeaubon",
    "voucherDescription": "Onze Yn'forma cadeaubon kan zowel voor diensten als in de winkel of webshop gebruikt worden. Een welgekomen geschenk voor een vriend of familielid met een viervoeter die een verzorging verdient!"
  }
}
</i18n>
