<template>
  <svg viewBox="0 0 256 256" stroke="currentColor" fill="none" stroke-width="18">
    <circle stroke-linecap="round" stroke-miterlimit="10" cx="186.719" cy="190.782" r="23.218"/>
	<circle stroke-linecap="round" stroke-miterlimit="10" cx="74.719" cy="190.782" r="23.218"/>
	<path stroke-linecap="round" stroke-miterlimit="10" d="
		M29,184h23.509c2.902-10,11.746-16.437,22.21-16.437c10.463,0,19.307,6.437,22.209,16.437h67.581
		c2.901-10,11.745-16.437,22.21-16.437c10.463,0,19.307,6.437,22.209,16.437h16.789c0,0,19.373-47.34,21.705-57.103
		c1.943-8.138,1.835-21.897-25.398-21.897c-14.256,0-57.023,0-57.023,0"/>
	<path stroke-linecap="round" stroke-miterlimit="10" d="
		M38,56c0,0,67.411,0,89.881,0c42.926,0,43.097,20.524,40.033,33.351C164.238,104.739,129,183.5,129,183.5"/>
	<line stroke-linecap="round" stroke-miterlimit="10" x1="22" y1="89" x2="57" y2="89"/>
	<line stroke-linecap="round" stroke-miterlimit="10" x1="15" y1="117" x2="64" y2="117"/>
	<line stroke-linecap="round" stroke-miterlimit="10" x1="8" y1="145" x2="71" y2="145"/>
  </svg>
</template>
