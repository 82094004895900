// Global translations. Specific translations are included in the compontents and view files.

// Need to import fallback language up front.
import nl from './nl.json'

export default {
  /*en: {
    name: 'English',
    load: () => { return import('./en.json') }
  },*/
  nl: {
    name: 'Nederlands',
    messages: nl
  },
  // TODO: check for french whether spaces are non-breaking before ? or !. https://github.com/kazupon/vue-i18n/issues/318
  /*fr: {
    name: 'Français',
    load: () => { return import('./fr.json') }
  }*/
}
