import axios from 'axios'
import { decode } from 'html-entities'
import { i18n } from 'vue-lang-router'

const axiosInstance = axios.create({
  baseURL: '/api/',
  transformResponse: res => res // Don't transform to JSON before response interceptor (see below).
})

// Add current locale as Accept-Language header to all requests.
axiosInstance.interceptors.request.use(function (config) {
  config.headers['Accept-Language'] = i18n.global.locale
  return config
})

const parse = data => {
  if (data.length > 0) {
    const decodedData = decode(data) // Decode HTML special characters.
    try {
      return JSON.parse(decodedData) // Transform to JSON just as the default transformResponse function would.
    } catch (err) {
      console.error(err)
      throw err
    }
  } else {
    return data
  }
}

// Decode HTML entities in all responses.
axiosInstance.interceptors.response.use(function (res) {
  res.data = parse(res.data)
  return res
}, function (err) {
  err.response.data = parse(err.response.data)
  return Promise.reject(err)
})

const axiosErrorHandler = (err, values, actions, fallbackField) => {
  // If validation errors, display them at the respective fields.
  if (err.response && err.response.data.validationErrors && actions) {
    for (const validationError of err.response.data.validationErrors) {
      actions.setFieldError(validationError.field, validationError.message)
    }

    // Also show general error message.
    return err.response.data.message
  } else {
    if (!err.response || err.response.status === 500 || !err.response.data || !err.response.data.message) {
      console.error(err)

      if (fallbackField) {
        actions.setFieldError(fallbackField, i18n.global.t('unexpectedError'))
      }

      return i18n.global.t('unexpectedError')
    } else {
      if (fallbackField) {
        actions.setFieldError(fallbackField, err.response.data.message)
      }

      return err.response.data.message
    }
  }
}

export {
  axiosInstance as axios,
  axiosErrorHandler
}
