<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <BlackWhiteTemplate :title="$t('privacyPolicy')" :img="require('@/assets/rabbit-side-2.jpg')" title-size="xs">
    <div class="container py-8">
      <p>{{ $t('intro') }}</p>

      <h3 class="text-red mt-8">{{ $t('peeTitle') }}</h3>
      <p>{{ $t('pee1') }}</p>

      <h3 class="text-red mt-8">{{ $t('onTimeTitle') }}</h3>
      <p>{{ $t('onTime1') }}</p>
      <p>{{ $t('onTime2') }}</p>

      <h3 class="text-red mt-8">{{ $t('paymentOptionsTitle') }}</h3>
      <p>{{ $t('paymentOptions1') }}</p>
      <p>{{ $t('paymentOptions2') }}</p>

      <h3 class="text-red mt-8">{{ $t('noFoodTitle') }}</h3>
      <p>{{ $t('noFood1') }}</p>

      <h3 class="text-red mt-8">{{ $t('cancellationPolicyTitle') }}</h3>
      <p>{{ $t('cancellationPolicy1') }}</p>
      <p>{{ $t('cancellationPolicy2') }}</p>
      <p>{{ $t('cancellationPolicy3') }}</p>
      <p>{{ $t('cancellationPolicy4') }}</p>

      <h3 class="text-red mt-8">{{ $t('liabilityTitle') }}</h3>
      <p>{{ $t('liability1') }}</p>
      <p>{{ $t('liability2') }}</p>

      <h3 class="text-red mt-8">{{ $t('behaviorProblemsTitle') }}</h3>
      <p>{{ $t('behaviorProblems1') }}</p>
      <p>{{ $t('behaviorProblems2') }}</p>

      <h3 class="text-red mt-8">{{ $t('extraConditionsGroomingTitle') }}</h3>
      <p>{{ $t('extraConditionsGrooming1') }}</p>
      <p>{{ $t('extraConditionsGrooming2') }}</p>
      <p>{{ $t('extraConditionsGrooming3') }}</p>

      <h3 class="text-red mt-8">{{ $t('extraConditionsPhysiotherapyTitle') }}</h3>
      <p>{{ $t('extraConditionsPhysiotherapy1') }}</p>

      <br>
      <p class="italic">{{ $t('lastModified') }}: 27/09/2024.</p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'

export default {
  name: 'Conditions',
  components: {
    Head,
    BlackWhiteTemplate
  }
}
</script>

<i18n>
{
   
  "nl": {
    "head": {
      "title": "Algemene voorwaarden - Yn'forma",
    },
    "privacyPolicy": "Algemene voorwaarden",
    "intro": "Bij het maken van een afspraak, zowel telefonisch, online, of als in persoon, gaat de klant automatisch akkoord met onze algemene voorwaarden.",
    "peeTitle": "Uitlaten voor binnenkomst",
    "pee1": "Wij verzoeken u vriendelijk uw hond uit te laten voordat u naar de afspraak komt. Dit helpt uw hond zich comfortabel te voelen tijdens de sessie en voorkomt onnodige onderbrekingen.",
    "onTimeTitle": "Kom op tijd",
    "onTime1": "Kom alstublieft op de afgesproken tijd. Indien u te laat komt, kan de sessie worden ingekort, maar de volledige prijs zal in rekening worden gebracht.",
    "onTime2": "Bij meer dan 15 minuten te laat zonder bericht, behouden wij ons het recht voor om de afspraak als geannuleerd te beschouwen (zie Annulatievoorwaarden).",
    "paymentOptionsTitle": "Betaalmogelijkheden",
    "paymentOptions1": "Betalingen worden direct na de sessie voldaan.",
    "paymentOptions2": "Wij accepteren zowel contante betalingen als betalingen via online banking (zoals Payconiq of bankoverschrijving).",
    "noFoodTitle": "Geen eten geven voor de sessie",
    "noFood1": "Geef uw hond alstublieft geen eten binnen twee uur voor de sessie. Een volle maag kan ongemak veroorzaken tijdens de behandeling.",
    "cancellationPolicyTitle": "Annulatievoorwaarden",
    "cancellationPolicy1": "Annuleren tot 24 uur voor de afspraak: kosteloos.",
    "cancellationPolicy2": "Annuleren binnen 24 uur voor de afspraak: 50% van de afgesproken prijs wordt in rekening gebracht.",
    "cancellationPolicy3": "Niet verschijnen of afspraak vergeten: de volledige prijs van de sessie wordt in rekening gebracht.",
    "cancellationPolicy4": "Annuleren kan telefonisch of via e-mail. Wij verzoeken u annuleringen zo spoedig mogelijk door te geven.",
    "liabilityTitle": "Aansprakelijkheid",
    "liability1": "Wij zijn niet aansprakelijk voor enige schade of verwonding aan uw hond tijdens de sessie, tenzij er sprake is van grove nalatigheid van onze kant.",
    "liability2": "Indien uw hond gezondheidsproblemen heeft, verzoeken wij u dit vooraf te melden. Wij behouden ons het recht voor om een behandeling te weigeren indien dit niet veilig wordt geacht.",
    "behaviorProblemsTitle": "Huisdieren met gedragsproblemen",
    "behaviorProblems1": "Indien uw hond gedragsproblemen vertoont (bijvoorbeeld agressie of angst), verzoeken wij u dit vooraf te melden. In sommige gevallen kan een muilkorf of ander veiligheidsmiddel nodig zijn.",
    "behaviorProblems2": "Indien wij de behandeling niet veilig kunnen uitvoeren, behouden wij ons het recht voor de sessie af te breken. De kosten worden dan naar rato van de verstreken tijd in rekening gebracht.",
    "extraConditionsGroomingTitle": "Extra voorwaarden trimsalon",
    "extraConditionsGrooming1": "Supplement vlooien: indien tijdens de trimbeurt bij uw huisdier vlooien worden geconstateerd, wordt een supplement van €\xa010 in rekening gebracht voor extra reiniging, desinfectie van de werkplek. Ook wordt uw hond extra gewassen met anti-vlo shampoo.",
    "extraConditionsGrooming2": "Klitten en vilt: bij ernstige klitten of vervilting van de vacht wordt niet geprobeerd dit uit te borstelen. Dit is te pijnlijk voor de hond en is overigens slecht voor huid en vacht. In dergelijke gevallen worden de mogelijke opties met u besproken, zoals het scheren van de vacht.",
    "extraConditionsGrooming3": "Niet tevreden? Mocht u niet tevreden zijn van de trimbeurt, laat dit dan binnen 5 dagen aan ons weten. Wij zullen ons uiterste best doen om samen een passende oplossing te vinden.",
    "extraConditionsPhysiotherapyTitle": "Extra voorwaarden fysiotherapie",
    "extraConditionsPhysiotherapy1": "Nodige informatie en verslagen: om de juiste behandeling van uw hond te kunnen bieden, is het noodzakelijk dat u ons voorziet van relevante informatie en verslagen van uw dierenarts. Dit helpt ons om de gezondheidstoestand van uw hond beter te begrijpen en een gerichte behandeling op te stellen.",
    "lastModified": "Laatste wijziging"
  }
}
</i18n>
