<template>
  <div class="flex flex-col">
    <div :style="{ 'background-image': `url(${img})` }" class="h-12 w-full bg-cover bg-center rounded-lg transform flex items-center p-4">
      <div class="text-xl text-white">{{ title }}</div>
    </div>

    <div class="my-4">
      <slot></slot>
    </div>

    <div class="flex-grow"></div>

    <localized-link :to="{ path: path, hash: hash }">
      <Button>
        {{ $t('more') }}
        <template v-slot:iconRight>
          <ChevronRightIcon/>
        </template>
      </Button>
    </localized-link>
  </div>
</template>

<script>
import Button from '@/components/Button'
import { ChevronRightIcon } from '@heroicons/vue/solid'

export default {
  name: 'ServiceCard',
  components: {
    Button,
    ChevronRightIcon
  },
  props: {
    img: String,
    title: String,
    description: String,
    path: String,
    hash: String
  }
}
</script>
