import { createStore } from 'vuex'
import { axios } from '@/utils/axios'
import Cookies from 'js-cookie'

let initShoppingCart = []
if (Cookies.get('shoppingCart')) {
  try {
    initShoppingCart = JSON.parse(Cookies.get('shoppingCart'))
  } catch (err) {
    console.error('Error parsing shopping cart cookie:', err)
  }
}

let initCheckoutDetailsShoppingCart
if (Cookies.get('checkoutDetailsShoppingCart')) {
  try {
    initCheckoutDetailsShoppingCart = JSON.parse(Cookies.get('checkoutDetailsShoppingCart'))
  } catch (err) {
    console.error('Error parsing shopping cart checkout details cookie:', err)
  }
}

let initCheckoutDetailsVoucher
if (Cookies.get('checkoutDetailsVoucher')) {
  try {
    initCheckoutDetailsVoucher = JSON.parse(Cookies.get('checkoutDetailsVoucher'))
  } catch (err) {
    console.error('Error parsing voucher checkout details cookie:', err)
  }
}

const store = createStore({
  state: {
    currentUser: undefined,
    currentCustomer: undefined,
    shoppingCart: initShoppingCart,
    checkoutDetailsShoppingCart: initCheckoutDetailsShoppingCart,
    checkoutDetailsVoucher: initCheckoutDetailsVoucher
  },
  getters: {
    getCurrentUser: state => state.currentUser,
    getCurrentCustomer: state => state.currentCustomer,
    getShoppingCart: state => state.shoppingCart,
    getShoppingCartItem: state => (product, variant) => state.shoppingCart.find(item => item.product === product && item.variant === variant),
    getCheckoutDetailsShoppingCart: state => state.checkoutDetailsShoppingCart,
    getCheckoutDetailsVoucher: state => state.checkoutDetailsVoucher
  },
  mutations: {
    setCurrentUser: (state, user) => {
      state.currentUser = user
    },
    setCurrentCustomer: (state, customer) => {
      state.currentCustomer = customer
    },
    pushToShoppingCart: (state, item) => {
      if (item.quantity <= 0) {
        return
      }
      const item_ = state.shoppingCart.find(item_ => item_.product === item.product && item_.variant === item.variant)
      if (item_) {
        item_.quantity += item.quantity
      } else {
        state.shoppingCart.push(item)
      }
    },
    incrementQuantityShoppingCart: (state, { product, variant }) => {
      const item = state.shoppingCart.find(item => item.product === product && item.variant === variant)
      if (item) {
        item.quantity++
      }
    },
    decrementQuantityShoppingCart: (state, { product, variant }) => {
      const item = state.shoppingCart.find(item => item.product === product && item.variant === variant)
      if (item) {
        item.quantity--
        if (item.quantity === 0) {
          // Remove from shopping cart.
          state.shoppingCart = state.shoppingCart.filter(item => item.product !== product || item.variant !== variant)
        }
      }
    },
    removeFromShoppingCart: (state, { product, variant }) => {
      state.shoppingCart = state.shoppingCart.filter(item => item.product !== product || item.variant !== variant)
    },
    clearShoppingCart: state => {
      state.shoppingCart = []
    },
    setCheckoutDetailsShoppingCart: (state, checkoutDetailsShoppingCart) => {
      state.checkoutDetailsShoppingCart = checkoutDetailsShoppingCart
    },
    clearCheckoutDetailsShoppingCart: state => {
      delete state.checkoutDetailsShoppingCart
    },
    setCheckoutDetailsVoucher: (state, checkoutDetailsVoucher) => {
      state.checkoutDetailsVoucher = checkoutDetailsVoucher
    },
    clearCheckoutDetailsVoucher: state => {
      delete state.checkoutDetailsVoucher
    }
  },
  actions: {
    async fetchCurrentUser ({ commit }) {
      try {
        const res = await axios.get('/users/current')
        commit('setCurrentUser', res.data)
        return res.data
      } catch (err) {
        // Not logged in, or error. TODO: should set null here or only if not logged in?
        commit('setCurrentUser', null)

        // If not logged in, return user null. If other error, rethrow it.
        if (err.response.data.code === 401) {
          return null
        } else {
          throw err
        }
      }
    },
    async fetchCurrentCustomer ({ commit }) {
      try {
        const res = await axios.get('/customers/current')
        commit('setCurrentCustomer', res.data)
        return res.data
      } catch (err) {
        commit('setCurrentCustomer', null)

        // If not logged in, return customer null. If other error, rethrow it.
        if (err.response.data.code === 401) {
          return null
        } else {
          throw err
        }
      }
    }
  },
  modules: {
  }
})

store.watch(
  (state, getters) => getters.getShoppingCart,
  (newVal, oldVal) => {
    Cookies.set('shoppingCart', JSON.stringify(newVal), {
      expires: 90, // Expires in 90 days.
      sameSite: 'strict'
    })
  },
  { deep: true }
)

store.watch(
  (state, getters) => getters.getCheckoutDetailsShoppingCart,
  (newVal, oldVal) => {
    if (!newVal) {
      Cookies.remove('checkoutDetailsShoppingCart')
    } else {
      Cookies.set('checkoutDetailsShoppingCart', JSON.stringify(newVal), {
        expires: 1, // Expires in 1 day.
        sameSite: 'strict'
      })
    }
  },
  { deep: true }
)

store.watch(
  (state, getters) => getters.getCheckoutDetailsVoucher,
  (newVal, oldVal) => {
    if (!newVal) {
      Cookies.remove('checkoutDetailsVoucher')
    } else {
      Cookies.set('checkoutDetailsVoucher', JSON.stringify(newVal), {
        expires: 1, // Expires in 1 day.
        sameSite: 'strict'
      })
    }
  },
  { deep: true }
)

export default store
