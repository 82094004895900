import { reactive } from 'vue'
import tailwindConfig from 'tailwindcss/defaultTheme'
import '../tailwind.css'

const parsedBreakpoints = Object.fromEntries(
  Object.entries(tailwindConfig.screens).map(
    ([k, v]) => [k, parseFloat(v)]
  )
)
const breakpointStatus = reactive({})

const calculateBreakpointStatus = () => {
  const newBreakpointStatus = Object.fromEntries(
    Object.entries(parsedBreakpoints).map(
      ([k, v]) => [k, window.innerWidth > v]
    )
  )

  Object.assign(breakpointStatus, newBreakpointStatus)
}

calculateBreakpointStatus()

const debounce = function (func, wait) {
  var timeout
  return () => {
    clearTimeout(timeout)
    timeout = setTimeout(func, wait)
  }
}

window.addEventListener(
  'resize',
  debounce(calculateBreakpointStatus, 200),
  false
)

export default {
  install: app => {
    app.config.globalProperties.$tailwind = {
      breakpoints: breakpointStatus
    }
  }
}
