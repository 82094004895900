<template>
  <!-- TODO: when switching languages, update user preferences in server as well. -->
  <!--<language-switcher v-slot="{ links }" tag="ul" active-class="active-locale">
    <li :class="link.activeClass" v-for="link in links" :key="link.langIndex">
      <a :href="link.url">{{ link.langIndex }}</a>
    </li>
  </language-switcher>-->
  <router-view/>
</template>

<style scoped>
.active-locale {
  font-style: italic;
}
</style>
