<template>
  <div class="relative overflow-hidden">
    <img v-for="(img, index) in imgs" :key="index" class="h-full w-full object-cover transition-opacity duration-3000 absolute" :class="{ 'opacity-0': activeImg != index, 'z-10': activeImg == index }" :src="img" :alt="alts[index]">
    <button v-if="arrows" @click="previousImage" class="absolute top-0 bottom-0 left-0 my-auto h-10 w-10 z-20 bg-white bg-opacity-30 rounded-r">
      <ChevronLeftIcon class="text-white"/>
    </button>
    <button v-if="arrows" @click="nextImage" class="absolute top-0 bottom-0 right-0 my-auto h-10 w-10 z-20 bg-white bg-opacity-30 rounded-l">
      <ChevronRightIcon class="text-white"/>
    </button>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

export default {
  name: 'FadingImages',
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  props: {
    imgs: Array,
    alt: {
      type: [String, Array],
      default: null
    },
    arrows: {
      type: Boolean,
      default: true
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeImg: 0,
      interval: null
    }
  },
  computed: {
    alts () {
      if (Array.isArray(this.alt)) {
        // If alt is array, make sure it's the same length as imgs array.
        if (this.alt.length >= this.imgs.length) {
          return this.alt
        } else {
          const alts = [...this.alt]
          for (let i = this.alt.length; i < this.imgs.length; i++) {
            alts.push('')
          }
          return alts
        }
      } else {
        // If alt is string, use string for every img. Or if not string, use empty string for every img.
        const alts = []
        for (let i = 0; i < this.imgs.length; i++) {
          if (typeof this.alt === 'string') {
            alts.push(this.alt)
          } else {
            alts.push('')
          }
        }
        return alts
      }
    }
  },
  methods: {
    switchImage () {
      this.activeImg = (this.activeImg + 1) % this.imgs.length
    },
    previousImage () {
      this.activeImg = (((this.activeImg - 1) % this.imgs.length) + this.imgs.length) % this.imgs.length
      this.resetInterval()
    },
    nextImage () {
      this.activeImg = (this.activeImg + 1) % this.imgs.length
      this.resetInterval()
    },
    resetInterval () {
      clearInterval(this.interval)
      this.interval = setInterval(this.switchImage, 7000)
    }
  },
  mounted () {
    this.interval = setInterval(this.switchImage, 7000)
    if (this.delay > 0 && this.delay < 7000) {
      setTimeout(this.resetInterval, this.delay)
    }
  },
  unmounted () {
    clearInterval(this.interval)
  }
}
</script>
