<template>
  <button :type="type" :disabled="disabled" class="flex items-center justify-center flex-shrink-0" :class="{ 'bg-gray-200 hover:bg-gray-100 rounded-lg py-2 px-3': !flat, 'hover:text-gray-500': flat, 'text-gray-500 cursor-default': disabled, 'hover:bg-gray-300': disabled && !flat }">
    <div class="icon icon-left inline">
      <slot name="iconLeft"></slot>
    </div>

    <div class="inline-block">
      <slot></slot>
    </div>

    <div class="icon icon-right inline">
      <slot name="iconRight"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    flat: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss">
.icon > * {
  @apply h-5 inline mb-0.5;
}

.icon-left > * {
  @apply mr-2;
}

.icon-right > * {
  @apply ml-2;
}
</style>
