<template>
  <div ref="collapseable" class="transition-height overflow-y-hidden" :style="{ height: collapseableHeight }">
    <slot/>
  </div>
</template>

<script>
// Keep an eye on performance for this. Might not be optimal.
export default {
  name: 'Collapse',
  props: {
    collapsed: Boolean
  },
  data () {
    return {
      collapseableHeight: null
    }
  },
  watch: {
    collapsed () {
      if (this.collapsed) {
        this.collapseableHeight = this.$refs.collapseable.scrollHeight + 'px' // Set to current height to enable transition.
        requestAnimationFrame(() => this.collapseableHeight = 0) // Before next repaint, hide.
      } else if (this.$refs.collapseable) {
        this.collapseableHeight = this.$refs.collapseable.scrollHeight + 'px' // Set to current height.

        // Set to auto again so container can resize if content changes.
        const listener = () => {
          this.$refs.collapseable.removeEventListener('transitionend', listener)
          this.collapseableHeight = null
        }
        this.$refs.collapseable.addEventListener('transitionend', listener)
      }
    }
  },
  created () {
    if (this.collapsed) {
      this.collapseableHeight = 0
    }
  }
}
</script>
