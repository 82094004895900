<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <BlackWhiteTemplate :title="$t('shippingReturnPolicy')" :img="require('@/assets/rabbit-side-2.jpg')">
    <div class="container py-8">
      <p>{{ $t('intro') }}</p>
      
      <h3 class="text-red mt-8">{{ $t('deliveryTitle') }}</h3>
      <p>{{ $t('delivery1') }}</p>
      <p>{{ $t('delivery2') }}</p>

      <h3 class="text-red mt-8">{{ $t('returnTitle') }}</h3>
      <p>{{ $t('return1') }}</p>

      <br>
      <p class="italic">{{ $t('lastModified') }}: 10/05/2023.</p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'

export default {
  name: 'ShippingReturnPolicy',
  components: {
    Head,
    BlackWhiteTemplate
  }
}
</script>

<i18n>
{
   
  "nl": {
    "head": {
      "title": "Verzend- en retourbeleid - Yn'forma",
    },
    "shippingReturnPolicy": "Verzend- en retourbeleid",
    "intro": "Op deze pagina vindt u meer informatie over hoe wij uw online bestellingen verzenden en wat uw opties zijn indien u een artikel dat u via onze webshop besteld heeft wil terugsturen.",
    "deliveryTitle": "Levering",
    "delivery1": "Wij leveren uitsluitend in België.",
    "delivery2": "Indien alle bestelde artikels op voorraad zijn, zullen we ze binnen de 14 dagen verzenden. Bij bestelling van artikels die niet op voorraad zijn, of in andere uitzonderlijke gevallen, kan de leveringstermijn langer zijn. In dat geval wordt u hierover ingelicht.",
    "returnTitle": "Artikels retourneren",
    "return1": "U heeft tot 14 dagen na levering van uw bestelling de tijd om een retour schriftelijk (bijvoorbeeld per e-mail) aan ons te melden. Vanaf dan heeft u 14 dagen om de terugzending uit te voeren, waarvan u de kosten draagt. Artikels dienen onbeschadigd, in ongebruikte staat en in originele verpakking terug te worden gestuurd. Eens wij de teruggezonden artikels in oorspronkelijke staat ontvangen, betalen wij u de aankoopsom terug.",
    "lastModified": "Laatste wijziging"
  }
}
</i18n>
