<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <BlackWhiteTemplate :title="$t('notFoundTitle')" :img="require('@/assets/rabbit-side-2.jpg')">
    <div class="container py-8">
      <p>{{ $t('notFound') }}</p>
      <localized-link :to="{ path: '/' }" class="text-red font-semibold">{{ $t('backToHome') }}</localized-link>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'

export default {
  name: 'NotFound',
  components: {
    Head,
    BlackWhiteTemplate
  }
}
</script>

<i18n>
{
   "en": {
    "head": {
      "title": "Page doesn't exist - Yn'forma",
    },
    "notFoundTitle": "Page doesn't exist",
    "notFound": "The page you're looking for doesn't exist (anymore).",
    "backToHome": "Click here to go back to the home page."
  },
  "nl": {
    "head": {
      "title": "Pagina bestaat niet - Yn'forma",
    },
    "notFoundTitle": "Pagina bestaat niet",
    "notFound": "De pagina die u zoekt bestaat niet (meer).",
    "backToHome": "Klik hier om terug naar de beginpagina te gaan."
  },
  "fr": {
    "head": {
      "title": "Page inexistante - Yn'forma",
    },
    "notFoundTitle": "Page inexistante",
    "notFound": "La page que vous cherchez n'existe pas ou plus.",
    "backToHome": "Cliquez ici pour retourner à la page d'accueil."
  }
}
</i18n>
