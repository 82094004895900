import * as yup from 'yup'

yup.addMethod(yup.string, 'password', function (errorMessageMin, errorMessageNumber, errorMessageLetter) {
  return this
    .min(8, errorMessageMin) // At least 8 characters.
    .matches(/\d/, errorMessageNumber) // At least one number.
    .matches(/[a-zA-Z]/, errorMessageLetter) // At least one letter.
})

yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  return this
    .matches(/^\+?([0-9])*$/, errorMessage) // One optional plus sign (+) at start of string, rest are numbers. Empty string passes.
})

yup.addMethod(yup.mixed, 'unique', function (errorMessage) {
  return this.test('unique', errorMessage, function (value) {
    return value === undefined || value === null || value === '' || this.parent.filter(v => v === value).length < 2
  })
})

yup.addMethod(yup.mixed, 'requiredIf', function (required, errorMessage) {
  if (required) {
    return this
      .required(errorMessage)
  } else {
    return this
  }
})

yup.addMethod(yup.number, 'maxDecimalPlaces', function (max, errorMessage) {
  return this
    .test('maxDecimalPlaces', errorMessage, function (value) {
      if (value !== null && value !== undefined) {
        return /^\d+(\.\d{1,2})?$/.test(value)
      }

      return true
    })
})