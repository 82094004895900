<template>
  <Head>
    <title>{{ product ? product.name + ' - ' : '' }} {{ $t('head.titleSuffix') }}</title>
    <meta property="og:title" :content="(product ? product.name + ' - ' : '') + $t('head.titleSuffix')">
    <!-- TODO add other tags -->
  </Head>

  <div class="flex-grow">
    <Loading :show="loading" :showingCallback="function () { product = null; }" :label="$t('loadingProduct')"/>

    <div v-if="product">
      <h2>{{ product.name }}</h2>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
        <div>
          <div v-if="product.files.length > 0" class="h-72 md:h-120">
            <FileGallery v-model="showFiles" :files="product.files" :index="0" :maxPreview="maxPreview"/>
          </div>
          <img v-else :src="require('@/assets/no-image.png')" class="h-72 mx-auto rounded-lg" style="aspect-ratio: 1">
        </div>

        <div>
          <div v-if="product.variants.length > 1" class="flex gap-x-2 mb-2">
            <div>{{ product.variantType[0].toUpperCase() + product.variantType.slice(1) }}:</div>
            <select v-model="selectedVariant" class="ring-1 ring-gray-500 rounded-sm">
              <option v-for="(variant, index) in product.variants" :key="index" :value="index">{{ variant.name }}</option>
            </select> 
          </div>
          
          <div class="mb-4">
            <div v-if="product.variants[selectedVariant].available === undefined" class="text-yellow-500">{{ $t('noAvailabilityInfo') }}</div>
            <div v-else-if="product.variants[selectedVariant].available > 0" class="text-green-600">{{ $t('available') }}</div>
            <div v-else class="text-yellow-500">{{ $t('notAvailable') }}</div>
          </div>

          <div class="text-3xl mb-6">€&nbsp;{{ product.variants[selectedVariant].price.toFixed(2).replace(/[.,]0+$/, "") }}</div>
                      
          <div class="flex items-start gap-x-4">
            <div class="flex ring-1 ring-inset ring-gray-200 rounded-lg">
              <Button @click="quantity = Math.max(1, quantity - 1)" class="w-8 rounded-r-none">-</Button>
              <div class="w-8 p-2 py-2 flex justify-center items-center">{{ quantity }}</div>
              <Button @click="quantity++" class="w-8 rounded-l-none">+</Button>
            </div>

            <div class="grid flex-shrink-0">
              <Button @click="addToShoppingCart" class="col-start-1 col-end-1 row-start-1 row-end-1 z-10" :class="{ 'opacity-0': recentlyAdded, 'transition-opacity duration-1000': !recentlyAdded }">
                <template v-slot:iconLeft>
                  <ShoppingCartIcon/>
                </template>
                {{ $t('inShoppingCart') }}
              </Button>
              
              <Button class="bg-green-600 hover:bg-green-600 opacity-0 col-start-1 col-end-1 row-start-1 row-end-1 text-white" :class="{ 'opacity-100': recentlyAdded, 'transition-opacity duration-1000': !recentlyAdded }">
                <template v-slot:iconLeft>
                  <CheckIcon/>
                </template>
                {{ $t('inShoppingCart') }}
              </Button>
            </div>
          </div>

          <localized-link :to="{ path: `/winkelwagen` }" v-if="quantityInShoppingCart > 0" class="block mt-2">{{ $t('currentlyInShoppingCart', { quantity: quantityInShoppingCart }) }}</localized-link>
        </div>

        <div v-if="product.description" class="sm:col-span-2">
          <h2>{{ $t('productDescription') }}</h2>
          <div v-html-sanitized="product.description"></div>
        </div>
      </div>
    </div>

    <div v-if="errorMessage" class="text-red">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { Head } from '@vueuse/head'
import { ShoppingCartIcon, CheckIcon } from '@heroicons/vue/outline'
import Button from '@/components/Button'
import Loading from '@/components/Loading'
import FileGallery from '@/components/FileGallery'

export default {
  name: 'Product',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    ShoppingCartIcon,
    CheckIcon,
    Button,
    Loading,
    FileGallery
  },
  emits: ['close'],
  data () {
    return {
      loading: true,
      product: null,
      errorMessage: null,
      showFiles: true,
      selectedVariant: 0,
      quantity: 1,
      recentlyAdded: false
    }
  },
  watch: {
    selectedVariant () {
      this.quantity = 1
    }
  },
  computed: {
    maxPreview () {
      if (this.$tailwind.breakpoints.xl) {
        return 5
      }
      if (this.$tailwind.breakpoints.lg) {
        return 4
      }
      if (this.$tailwind.breakpoints.sm) {
        return 3
      }
      return 4
    },
    quantityInShoppingCart () {
      if (!this.product) {
        return 0
      }

      const item = this.$store.getters.getShoppingCartItem(this.product.id, this.product.variants[this.selectedVariant].id)
      if (item) {
        return item.quantity
      } else {
        return 0
      }
    }
  },
  methods: {
    getProduct () {
      this.loading = true
      this.$axios.get(`/products/${this.$route.params.productId}`, {
        params: {
          populate: 'files' // TODO: check if possible to only get file MIME type.
        }
      })
        .then(res => {
          this.loading = false
          this.errorMessage = null
          this.product = res.data
          
          // Remove discontinued variants.
          this.product.variants = this.product.variants.filter(v => !v.discontinued)
          
          this.selectedVariant = 0
          this.quantity = 1
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    },
    addToShoppingCart () {
      this.$store.commit('pushToShoppingCart', {
        product: this.product.id,
        variant: this.product.variants[this.selectedVariant].id,
        quantity: this.quantity
      })
      this.quantity = 1

      this.recentlyAdded = true
      setTimeout(() => this.recentlyAdded = false, 3000)
    }
  },
  created () {
    this.getProduct()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "titleSuffix": "Yn'forma"
    },
    "loadingProduct": "Product laden",
    "available": "Op voorraad",
    "notAvailable": "Geen voorraad, langere levertermijn",
    "noAvailabilityInfo": "Voorraad ongekend, mogelijks langere levertermijn",
    "inShoppingCart": "In winkelwagen",
    "currentlyInShoppingCart": "Reeds {quantity} in winkelwagen",
    "productDescription": "Productbeschrijving"
  }
}
</i18n>
