<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="og:title" :content="title">
    <!-- TODO add other tags -->
  </Head>

  <div class="flex-grow">
    <h2>{{ $route.name === 'shoppingCart' ? $t('shoppingCart') : $t('checkout') }}</h2>

    <Loading :show="loading" :label="$t('loadingShoppingCart')"/>
    
    <div v-if="!loading && !errorMessage">
      <div v-if="items.length === 0">
        <p class="italic">{{ $t('noItems') }}</p>
        <localized-link :to="{ path: `/winkel` }" class="mt-6">
          <Button>
            {{ $t('toShop') }}
            <template v-slot:iconRight>
              <ChevronRightIcon/>
            </template>
          </Button>
        </localized-link>
      </div>

      <!-- Start shopping cart -->
      <div v-else-if="$route.name === 'shoppingCart'">
        <div v-for="(item, index) in items" :key="index">
          <div class="flex justify-center gap-x-6">
            <localized-link :to="{ path: `/winkel/${item.product.id}` }" class="h-28 w-28 flex-shrink-0 flex items-center">
              <img v-if="item.product && item.product.image" :src="`/api/files/${item.product.image.id}/content`" class="h-full object-cover rounded-lg" style="aspect-ratio: 1">
              <img v-else :src="require('@/assets/no-image.png')" class="h-24 mx-auto rounded-lg" style="aspect-ratio: 1">
            </localized-link>

            <div class="flex-grow">
              <localized-link :to="{ path: `/winkel/${item.product.id}` }">
                <div>{{ item.product.name }}</div>
                <div v-if="item.product.variantType && item.variant.name">{{ item.product.variantType }}: {{ item.variant.name }}</div>
              </localized-link>
              <div class="flex items-center mt-3">
                <div class="flex ring-1 ring-inset ring-gray-200 rounded-lg">
                  <Button @click="$store.commit('decrementQuantityShoppingCart', { product: item.product.id, variant: item.variant.id })" class="w-8 rounded-r-none">-</Button>
                  <div class="w-8 p-2 py-2 flex justify-center items-center">{{ item.quantity }}</div>
                  <Button @click="$store.commit('incrementQuantityShoppingCart', { product: item.product.id, variant: item.variant.id })" class="w-8 rounded-l-none">+</Button>
                </div>
                
                <Button @click="$store.commit('removeFromShoppingCart', { product: item.product.id, variant: item.variant.id })" flat class="ml-3">
                  <TrashIcon class="h-5"/>
                </Button>

                <div class="flex-grow"></div>

                <div>€&nbsp;{{ (item.quantity * item.variant.price).toFixed(2).replace(/[.,]0+$/, "") }}</div>
              </div>
            </div>
          </div>

          <hr class="md:ml-34 my-6">
        </div>

        <div class="text-right" :class="{ 'text-red': totalPrice > maxOrderPrice }">{{ $t('totalExcl') }} €&nbsp;{{ totalPrice.toFixed(2).replace(/[.,]0+$/, "") }}</div>

        <div v-if="totalPrice > maxOrderPrice" class="text-red flex items-start justify-end">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ $t('maxOrderPriceExceeded', { maxOrderPrice: maxOrderPrice.toFixed(2).replace(/[.,]0+$/, "") }) }}</span>
        </div>

        <div class="flex justify-end mt-6">
          <component :is="totalPrice <= maxOrderPrice ? 'localized-link' : 'div'" :to="{ path: `/bestellen` }">
            <Button :disabled="totalPrice > maxOrderPrice">
              {{ $t('order') }}
              <template v-slot:iconRight>
                <ChevronRightIcon/>
              </template>
            </Button>
          </component>
        </div>
      </div>
      <!-- End shopping cart -->

      <!-- Start checkout -->
      <div v-else>
        <Form @submit="order" :validation-schema="orderSchema" :initial-values="orderInitial" v-slot="{ values, errors, setFieldValue, isSubmitting, setFieldError }" @keydown.enter="$event.preventDefault()">
          <div class="max-w-xl bg-gray-100 rounded-lg p-4">
            <div class="flex" :class="{ 'text-red': errors && Object.keys(errors).some(k => k.substring(0, 5) === 'items') }">
              <ExclamationIcon v-show="errors && Object.keys(errors).some(k => k.substring(0, 5) === 'items')" class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
              <div>{{ itemsInShoppingCart }} {{ $tc('items', itemsInShoppingCart)}}</div>
              <button type="button" class="ml-3">
                <ChevronUpIcon class="h-0 w-5 transition-height duration-500" :class="{ 'h-5': showItems }" @click="showItems = false"/>
                <ChevronDownIcon class="h-0 w-5 transition-height duration-500" :class="{ 'h-5': !showItems }" @click="showItems = true"/>
              </button>
              <div class="flex-grow"></div>
              <div>€&nbsp;{{ totalPrice.toFixed(2).replace(/[.,]0+$/, "") }}</div>
            </div>

            <Collapse :collapsed="!showItems">
              <div v-for="(item, index) in items" :key="index">
                <div class="flex justify-between mt-2 text-base" :class="{ 'text-red': errors && (errors[`items[${index}].variant`] || errors[`items[${index}].product`]) }">
                  <div>
                    {{ item.quantity }}x {{ item.product.name }}
                    <span v-if="item.product.variantType && item.variant.name">({{ item.product.variantType }}: {{ item.variant.name }})</span>
                  </div>
                  <div>€&nbsp;{{ (item.quantity * item.variant.price).toFixed(2).replace(/[.,]0+$/, "") }}</div>
                </div>

                <div v-if="errors && errors[`items[${index}].variant`]" class="mt-2 text-red flex items-start text-base">
                  <ExclamationIcon class="inline h-6 mr-3 top-0.5 relative flex-shrink-0"/>
                  <span>{{ errors[`items[${index}].variant`] }}</span>
                </div>

                <div v-if="errors && errors[`items[${index}].product`]" class="mt-2 text-red flex items-start text-base">
                  <ExclamationIcon class="inline h-6 mr-3 top-0.5 relative flex-shrink-0"/>
                  <span>{{ errors[`items[${index}].product`] }}</span>
                </div>
              </div>
            </Collapse>
            
            <div v-if="delivery" class="flex mt-2">
              <div>{{ $t('deliveryCosts') }}</div>
              <div class="flex-grow"></div>
              <div>€&nbsp;{{ deliveryCosts.toFixed(2).replace(/[.,]0+$/, "") }}</div>
            </div>

            <div v-show="totalPriceWithDelivery <= maxOrderPrice" v-for="(voucher, index) in vouchers" :key="index" class="flex mt-2">
              <div>{{ $tc('voucher', 1) }}</div>
              <div class="flex-grow"></div>
              <div>-&nbsp;€&nbsp;{{ getUsedAmountFromVoucher(index).toFixed(2).replace(/[.,]0+$/, "") }}</div>
            </div>

            <div class="flex font-bold mt-2" :class="{ 'text-red': (errors && errors.totalPrice) || totalPriceWithDelivery > maxOrderPrice }">
              <div>{{ $t('total') }}</div>
              <div class="flex-grow"></div>
              <div v-if="totalPriceWithDelivery > maxOrderPrice">€&nbsp;{{ totalPriceWithDelivery.toFixed(2).replace(/[.,]0+$/, "") }}</div>
              <div v-else>€&nbsp;{{ totalPriceWithDeliveryAndVouchers.toFixed(2).replace(/[.,]0+$/, "") }}</div>
            </div>

            <div v-if="errors && errors.items" class="mt-4 text-red flex items-start">
              <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
              <span>{{ errors.items }}</span>
            </div>

            <div v-if="errors && errors.totalPrice" class="mt-4 text-red flex items-start">
              <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
              <span>{{ errors.totalPrice }}</span>
            </div>

            <div v-else-if="totalPriceWithDelivery > maxOrderPrice" class="mt-4 text-red flex items-start">
              <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
              <span>{{ $t('maxOrderPriceExceeded', { maxOrderPrice: maxOrderPrice.toFixed(2).replace(/[.,]0+$/, "") }) }}</span>
            </div>
          </div>

          <div v-show="totalPrice <= maxOrderPrice" class="grid grid-cols-2 gap-x-4 max-w-xl mt-4">
            <div @click="delivery = true" class="flex flex-col sm:flex-row flex-wrap gap-2 bg-gray-100 p-4 rounded-lg justify-center items-center content-center cursor-pointer hover:bg-gray-50" :class="{ 'ring-2 ring-gray-400 hover:bg-gray-100': delivery === true }">
              <DeliveryIcon class="h-7 text-gray-700"/>
              <div class="text-center">{{ $t('homeDelivery') }}</div>
            </div>
            <div @click="delivery = false" class="flex flex-col sm:flex-row flex-wrap gap-2 bg-gray-100 p-4 rounded-lg justify-center items-center content-center cursor-pointer hover:bg-gray-50" :class="{ 'ring-2 ring-gray-400 hover:bg-gray-100': delivery === false }">
              <BuildingStorefrontIcon class="h-7 text-gray-700"/>
              <div class="text-center">{{ $t('pickUpInStore') }}</div>
            </div>
          </div>

          <div v-show="delivery !== null && totalPriceWithDelivery <= maxOrderPrice" class="mt-4">
            <Field v-if="!delivery" name="payUponRetrieval" type="radio" :radioValues="payUponRetrievalRadioValues" class="mb-4"/>

            <h3>{{ $t('contactDetails') }}</h3>
            <Field name="contactDetails.firstName" type="text" :label="$t('firstName')" autocomplete="given-name" class="mb-4"/>
            <Field name="contactDetails.lastName" type="text" :label="$t('lastName')" autocomplete="family-name" class="mb-4"/>
            <Field name="contactDetails.email" type="text" :label="$t('email')" autocomplete="email" class="mb-4"/>
            <Field name="contactDetails.phoneNumber" type="text" :label="$t('phoneNumber')" autocomplete="tel" class="mb-4"/>

            <h3 v-if="delivery">{{ $t('address') }}</h3>
            <Field v-if="delivery" name="contactDetails.address.street" type="text" :label="$t('street')" autocomplete="address-line1" class="mb-4"/>
            <Field v-if="delivery" name="contactDetails.address.houseNumber" type="text" :label="$t('houseNumber')" autocomplete="address-line2" class="mb-4"/>
            <Field v-if="delivery" name="contactDetails.address.box" type="text" :label="$t('box')" :default="null" autocomplete="address-line3" class="mb-4"/>
            <Field v-if="delivery" name="contactDetails.address.postalCode" type="text" :label="$t('postalCode')" autocomplete="postal-code" class="mb-4"/>
            <Field v-if="delivery" name="contactDetails.address.town" type="text" :label="$t('town')" autocomplete="address-level2" class="mb-4"/>
            <Field v-if="delivery" name="contactDetails.address.country" type="text" :label="$t('country')" autocomplete="country-name" class="mb-4"/>

            <h3>{{ $t('comment') }}</h3>
            <Field name="comment" type="textarea" :label="$t('comment')" :rows="5" class="mb-4"/>

            <h3>{{ $tc('voucher', 2) }}</h3>
            <FieldArray name="vouchers" v-slot="{ fields, push, remove }">
              <div v-for="(entry, index) in fields" :key="entry.key">
                <div class="flex mb-4">
                  <div>{{ entry.value.code }}</div>
                  <button type="button" class="ml-3">
                    <XIcon @click="remove(index); removeVoucher(index)" class="h-7"/>
                  </button>
                </div>

                <span v-if="errors[`vouchers[${index}]`]" class="mb-4 text-red flex items-start">
                  <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
                  <span>{{ errors[`vouchers[${index}]`] }}</span>
                </span>

                <span v-if="errors[`vouchers[${index}].code`]" class="mb-4 text-red flex items-start">
                  <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
                  <span>{{ errors[`vouchers[${index}].code`] }}</span>
                </span>
              </div>

              <Field name="code" type="text" :label="$t('addVoucher')" class="mb-4" @keydown.enter="addVoucher(fields, values.code, push, setFieldValue, values, setFieldError)">
                <template v-slot:right>
                  <button type="button">
                    <PlusIcon @click="addVoucher(fields, values.code, push, setFieldValue, values, setFieldError)" class="h-6 ml-2"/>
                  </button>
                </template>
              </Field>

              <span v-show="errors['vouchers']" class="mb-4 text-red flex items-start">
                <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
                <span>{{ errors['vouchers'] }}</span>
              </span>
            </FieldArray>

            <template v-if="totalPriceWithDeliveryAndVouchers > 0 && (delivery || !values.payUponRetrieval)">
              <h3>{{ $t('paymentMethod') }}</h3>
              <Field name="paymentMethod" type="radio" :radioValues="paymentMethodRadioValues" class="mb-4"/>
            </template>

            <p v-if="!delivery" class="mb-4">{{ $t('attentionRetrieval') }}</p>

            <div v-show="orderErrorMessage" class="mb-4 text-red flex items-start">
              <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
              <span>{{ orderErrorMessage }}</span>
            </div>

            <Button type="submit" :disabled="isSubmitting">{{ totalPriceWithDeliveryAndVouchers === 0 || (!delivery && values.payUponRetrieval) ? $t('confirmOrder') : $t('pay') }}</Button>
          </div>
        </Form>
      </div>
      <!-- End checkout -->
    </div>

    <div v-show="errorMessage" class="mb-4 text-red flex items-start">
      <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'
import { PlusIcon, XIcon, ExclamationIcon, ChevronRightIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { TrashIcon } from '@heroicons/vue/outline'
import DeliveryIcon from '@/components/icons/DeliveryIcon'
import BuildingStorefrontIcon from '@/components/icons/BuildingStorefrontIcon'
import { Form, FieldArray } from 'vee-validate'
import '@/validations'
import * as yup from 'yup'
import Field from '@/components/Field'
import Loading from '@/components/Loading'
import Button from '@/components/Button'
import Collapse from '@/components/Collapse'

export default {
  name: 'ShoppingCart',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    PlusIcon,
    XIcon,
    ExclamationIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    TrashIcon,
    DeliveryIcon,
    BuildingStorefrontIcon,
    Form,
    FieldArray,
    Field,
    Loading,
    Button,
    Collapse
  },
  data () {
    return {
      loading: true,
      errorMessage: null,
      delivery: null,
      products: {},
      maxOrderPrice: null,
      deliveryCosts: null,
      showItems: false,
      payUponRetrievalRadioValues: [{
        value: false,
        display: this.$t('payOnline')
      }, {
        value: true,
        display: this.$t('payUponRetrieval')
      }],
      paymentMethodRadioValues: [{
        value: 'bancontact',
        display: this.$t('bancontact')
      }, {
        value: 'creditcard',
        display: this.$t('creditCard')
      }],
      orderErrorMessage: null,
      vouchers: []
    }
  },
  watch: {
    $route (newVal, oldVal) {
      this.delivery = null
      this.showItems = false
    }
  },
  computed : {
    title () {
      return this.$route.name === 'shoppingCart' ? this.$t('head.titleShoppingCart') : this.$t('head.titleCheckout')
    },
    items () {
      return this.$store.getters.getShoppingCart.map(item => ({
        quantity: item.quantity,
        product: this.products[item.product],
        variant: this.products[item.product] ? this.products[item.product].variants.find(v => v.id === item.variant) : null
      }))
    },
    totalPrice () {
      return this.items.reduce((a, v) => a + v.variant.price * v.quantity, 0)
    },
    totalPriceWithDelivery () {
      return (this.delivery && this.deliveryCosts) ? this.totalPrice + this.deliveryCosts : this.totalPrice
    },
    totalPriceWithDeliveryAndVouchers () {
      let price = this.totalPriceWithDelivery
      for (const voucher of this.vouchers) {
        price = Math.max(0, price - voucher.amountRemaining)
      }
      return price
    },
    itemsInShoppingCart () {
      return this.items.reduce((a, v) => a + v.quantity, 0)
    },
    orderSchema () {
      return yup.object({
      contactDetails: yup.object({
        firstName: yup.string()
          .required(this.$t('validation.firstName.required')),
        lastName: yup.string()
          .required(this.$t('validation.lastName.required')),
        email: yup.string()
          .required(this.$t('validation.email.required'))
          .email(this.$t('validation.email.email')),
        phoneNumber: yup.string()
          .required(this.$t('validation.phoneNumber.required'))
          .phoneNumber(this.$t('validation.phoneNumber.format')),
        address: yup.object({
          street: yup.string()
            .requiredIf(this.delivery, this.$t('validation.street.required')),
          houseNumber: yup.string()
            .requiredIf(this.delivery, this.$t('validation.houseNumber.required')),
          box: yup.string()
            .nullable(),
          postalCode: yup.string()
            .requiredIf(this.delivery, this.$t('validation.postalCode.required')),
          town: yup.string()
            .requiredIf(this.delivery, this.$t('validation.town.required')),
          country: yup.string()
            .requiredIf(this.delivery, this.$t('validation.country.required'))
            .test('belgium', this.$t('validation.country.deliveryBelgium'), function (value) {
              // Accept if no value as not to trigger this error message when the required error message should be displayed.
              return !value || ['belgie', 'belgië', 'belgium', 'belgique', 'be'].includes(value.toLowerCase().trim())
            })
        })
      }),
      payUponRetrieval: yup.boolean(),
      notes: yup.string()
        .nullable(),
      paymentMethod: yup.string()
        .when('payUponRetrieval', (payUponRetrieval, schema) => {
          if (this.totalPriceWithDeliveryAndVouchers > 0 && (this.delivery || !payUponRetrieval)) {
            return schema.required(this.$t('validation.paymentMethod.required'))
          }
        })
      })
    },
    orderInitial () {
      return {
        items: this.$store.getters.getShoppingCart,
        ...this.$store.getters.getCheckoutDetailsShoppingCart && ({
          contactDetails: this.$store.getters.getCheckoutDetailsShoppingCart.contactDetails,
          comment: this.$store.getters.getCheckoutDetailsShoppingCart.comment,
          paymentMethod: this.$store.getters.getCheckoutDetailsShoppingCart.paymentMethod,
        }),
        payUponRetrieval: false,
        vouchers: this.vouchers.map(v => ({ code: v.code }))
      }
    }
  },
  methods: {
    getSettingsAndProducts () {
      this.loading = true
      this.errorMessage = null
      const promises = []

      const settingsPromise = this.$axios.get('/settings')
        .then(res => {
          this.maxOrderPrice = res.data.maxOrderPrice
          this.deliveryCosts = res.data.deliveryCosts
          this.webshopEnabled = res.data.webshopEnabled

          if (!res.data.webshopEnabled) {
            throw {
              response: {
                data: {
                  message: this.$t('webshopDisabled')
                }
              }
            }
          }
        })
        .catch(err => {
          this.errorMessage = this.$axiosErrorHandler(err)
        })
      promises.push(settingsPromise)

      for (const item of this.$store.getters.getShoppingCart) {
        const promise = this.$axios.get(`/products/${item.product}`, {
          params: {
            select: 'name variantType variants files discontinued',
            populate: 'files'
          }
        })
          .then(res => {
            // Remove from shopping cart if discontinued.
            if (res.data.discontinued) {
              this.$store.commit('removeFromShoppingCart', { product: item.product, variant: item.variant })
              return
            }
            
            // Filter out discontinued variants.
            res.data.variants = res.data.variants.filter(v => !v.discontinued)

            // Remove from shopping cart if discontinued.
            if (!res.data.variants.find(v => v.id === item.variant)) {
              this.$store.commit('removeFromShoppingCart', { product: item.product, variant: item.variant })
              return
            }

            // Select first image among files.
            res.data.image = res.data.files.find(el => el.mimeType.startsWith('image'))

            this.products[res.data.id] = res.data
          })
          .catch(err => {
            if (err.response.status === 404) {
              // Remove from shopping cart if discontinued.
              this.$store.commit('removeFromShoppingCart', { product: item.product, variant: item.variant })
            } else {
              this.errorMessage = this.$axiosErrorHandler(err)
            }
          })

          promises.push(promise)
      }
      
      Promise.all(promises)
        .finally(() => {
          this.loading = false
        })
    },
    addVouchersInitial (vouchers) {
      for (const voucher of vouchers) {
        // Don't do anything with empty values.
        if (!voucher.code || voucher.code.trim() === '') {
          continue
        }

        // Don't allow duplicates.
        if (this.vouchers.some(e => e.value.code === code)) {
          continue
        }

        this.$axios.get('/vouchers', {
          params: {
            code: voucher.code
          }
        })
          .then(res => {
            if (res.data.results.length > 0 && res.data.results[0].status === 'open' && res.data.results[0].amountRemaining > 0) {
              this.vouchers.push(res.data.results[0])
            } else {
              // Do nothing, ignore this code.
            }
          })
          .catch(err => {
            // Do nothing, ignore this code.
          })
      }
    },
    addVoucher (entries, code, push, setFieldValue, values, setFieldError) {
      setFieldError('code', null)

      // Don't do anything with empty values.
      if (!code || code.trim() === '') {
        return
      }

      // Don't allow duplicates.
      if (entries.some(e => e.value.code === code)) {
        setFieldError('code', this.$t('duplicateVoucher'))
        return
      }

      return this.$axios.get('/vouchers', {
        params: {
          code: code
        }
      })
        .then(res => {
          if (res.data.results.length > 0 && res.data.results[0].status === 'open' && res.data.results[0].amountRemaining > 0) {
            setFieldValue('code', undefined)
            this.vouchers.push(res.data.results[0])
            push({ code: code })
          } else {
            setFieldError('code', this.$t('voucherNotFound'))
          }
        })
        .catch(err => {
          this.$axiosErrorHandler(err, values, { setFieldError }, 'code')
        })
    },
    removeVoucher (index) {
      this.vouchers.splice(index, 1)
    },
    order (values, actions) {
      this.orderErrorMessage = null

      values.delivery = this.delivery
      if (this.delivery) {
        delete values.payUponRetrieval
      }

      this.$store.commit('setCheckoutDetailsShoppingCart', values)

      return this.$axios.post('/orders', values)
        .then(res => {
          if (!res.data.checkoutUrl) {
            this.$router.push({ path: '/bestelling-verwerkt', query: { 'order-number': res.data.orderNumber } })
          } else if (res.data.checkoutUrl) {
            window.location.replace(res.data.checkoutUrl)
          }
        })
        .catch(err => {
          this.orderErrorMessage = this.$axiosErrorHandler(err, values, actions)
        })
    },
    getUsedAmountFromVoucher (index) {
      let remaining = this.totalPriceWithDelivery
      for (let i = 0; i < index; i++) {
        remaining -= this.vouchers[i].amountRemaining
      }

      return Math.min(Math.max(remaining, 0), this.vouchers[index].amountRemaining)
    }
  },
  mounted () {
    if (this.$store.getters.getCheckoutDetailsShoppingCart && this.$store.getters.getCheckoutDetailsShoppingCart.vouchers) {
      this.addVouchersInitial(this.$store.getters.getCheckoutDetailsShoppingCart.vouchers)
    }
    this.getSettingsAndProducts()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "titleShoppingCart": "Winkelwagen - Yn'forma",
      "titleCheckout": "Bestellen - Yn'forma"
    },
    "shoppingCart": "Winkelwagen",
    "loadingShoppingCart": "Winkelwagen laden",
    "checkout": "Bestellen",
    "noItems": "Geen producten in de winkelwagen.",
    "toShop": "Naar de winkel",
    "totalExcl": "Totaal (excl. eventuele verzendkosten):",
    "total": "Totaal",
    "order": "Bestellen",
    "confirmOrder": "Bestelling bevestigen",
    "items": "artikel | artikels",
    "country": "Land",
    "belgium": "België",
    "deliveryCosts": "Verzendkosten",
    "homeDelivery": "Thuislevering",
    "pickUpInStore": "Afhalen in praktijk",
    "payOnline": "Online betalen",
    "payUponRetrieval": "Betalen bij afhalen",
    "comment": "Opmerking",
    "paymentMethod": "Betaalmethode",
    "bancontact": "Bancontact",
    "creditCard": "Kredietkaart",
    "maxOrderPriceExceeded": "Bestellingen gemaakt via de webshop zijn beperkt tot €\u00a0{maxOrderPrice}. Gelieve ons te contacteren om deze bestelling te plaatsen.",
    "attentionRetrieval": "Opgelet! Wij hebben geen vaste openingsuren maar zijn enkel open op afspraak. Eens uw bestelling beschikbaar is, wordt u hierover op de hoogte gebracht. Contacteer ons dan om een gepast moment af te spreken voor de afhaling. Zo voorkomt u dat u voor een gesloten deur staat.",
    "pay": "Betalen",
    "voucher": "Kortingscode of cadeaubon | Kortingscodes of cadeaubonnen",
    "voucherNotFound": "De kortingscode of cadeaubon met deze code is ofwel opgebruikt, ofwel is de code ongeldig.",
    "addVoucher": "Code toevoegen",
    "duplicateVoucher": "U heeft deze code al toegevoegd."
  }
}
</i18n>
