<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('puppyTraining')">
    <div class="container py-8">
      <div class="lg:flex">
        <div>
          <p>{{ $t('intro') }}</p>
          <h3 class="text-red">{{ $t('gymTitle') }}</h3>
          <p>{{ $t('gym1') }}</p>
          <img :src="require('@/assets/puppy-training-4.jpg')" class="lg:hidden rounded-lg mb-4 h-84 md:h-120 object-cover w-full" :alt="$t('altPuppyGym')">
          <p>{{ $t('gym2') }}</p>

          <h3 class="text-red">{{ $t('socializationTitle') }}</h3>
          <p>{{ $t('socialization') }}</p>
          <FadingImages :imgs="[require('@/assets/puppy-training-6.jpg'), require('@/assets/puppy-training-1a.jpg'), require('@/assets/puppy-training-7.jpg'), require('@/assets/puppy-training-5.jpg'), require('@/assets/puppy-training-9.jpg')]" :alt="[$t('altPuppyTrainingHabit'), $t('altPuppyTraining'), $t('altPuppyTraining'), $t('altPuppyTrainingHabit'), $t('altPuppyTraining')]" class="lg:hidden w-full h-96 md:h-120 rounded-lg"></FadingImages>
        </div>

        <div class="hidden lg:block ml-8 max-h-96 flex-grow">
          <div class="mx-auto w-64 xl:w-80 relative">
            <img :src="require('@/assets/puppy-training-4.jpg')" class="h-64 w-64 object-cover rounded-lg animate-grow-once" style="--r:rotate(6deg); animation-delay:0ms;" :alt="$t('altPuppyGym')">
            <img :src="require('@/assets/puppy-training-7.jpg')" class="hidden xl:block absolute top-92 left-56 h-64 w-64 object-cover rounded-lg animate-grow-once" style="--r:rotate(-6deg); animation-delay:300ms;" :alt="$t('altPuppyTraining')">
            <img :src="require('@/assets/puppy-training-5.jpg')" class="hidden xl:block absolute top-32 left-52 h-64 w-64 object-cover rounded-lg animate-grow-once" style="--r:rotate(3deg); animation-delay:900ms;" :alt="$t('altPuppyTrainingHabit')">
            <img :src="require('@/assets/puppy-training-6.jpg')" class="xl:relative xl:-left-8 h-64 w-64 object-cover rounded-lg animate-grow-once" style="--r:rotate(-3deg); animation-delay:600ms;" :alt="$t('altPuppyTrainingHabit')">
            <img :src="require('@/assets/puppy-training-1a.jpg')" class="h-64 w-64 object-cover rounded-lg animate-grow-once" style="--r:rotate(3deg); animation-delay:1200ms;" :alt="$t('altPuppyTraining')">
          </div>
        </div>
      </div>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import FadingImages from '@/components/FadingImages'

export default {
  name: 'PuppyTraining',
  components: {
    Head,
    BlackWhiteTemplate,
    FadingImages
  }
}
</script>

<style scoped lang="postcss">
.animate-grow-once {
  transform: scale(0);
  animation: grow .3s forwards;
}

@keyframes grow {
  0% {
    transform: scale(0) var(--r);
  }

  90% {
    transform: scale(1.1) var(--r);
  }

  100% {
    transform: scale(1) var(--r);
  }
}
</style>

<i18n>
{
  "nl": {
    "head": {
      "title": "Puppytraining - Yn'forma",
      "meta": {
        "description": "Puppytraining in Gooik. Socialisatie, puppygym en tips voor de baasjes.",
        "keywords": "puppytraining, puppygym, socialisatie, puppy, gooik, oetingen, hondenfitness, hondentraining, training, hondencoach, coach, coaching, yn'forma, ynforma, honden, hond, huisdier, huisdieren"
      }
    },
    "puppyTraining": "Puppytraining",
    "intro": "Bij de puppytraining worden verschillende zaken naar voren gebracht die belangrijk zijn voor de ontwikkeling van uw pup. We doen aan puppygym en werken aan socialisatie, en geven uitleg aan het baasje over de belangrijkste aandachtspunten tijdens de groei van de pup.",
    "gymTitle": "Puppygym",
    "gym1": "U kan het lichaamsbewustzijn van uw pup stimuleren op jonge leeftijd. Dit kan met kleine oefeningen waaruit u grote resultaten haalt. Tijdens de puppygymlessen wordt uw pup goed begeleid en krijgt uw pup de kans om kennis te maken met verschillende materialen. Om ervoor te zorgen dat uw pup niet overbelast wordt, is een correcte begeleiding hierbij heel belangrijk. Puppygym legt de basis voor balans en coördinatietraining die u in de pubertijd kan opstarten. Belangrijk tijdens de puppygym is dat u de band met uw pup enorm versterkt. Samen beleeft u uitdagingen waardoor uw pup niet alleen meer vertrouwen in zichzelf zal krijgen, maar ook in u als begeleider.",
    "gym2": "De oefeningen bij de puppytraining worden opgedeeld per leeftijd. We werken met groepen van 8-10 weken, 10-14 weken, 14-20 weken en 20-24 weken. Per leeftijdsgroep worden er aangepaste oefeningen voorzien in functie van wat het hondenlichaam op die leeftijd aankan en worden er tips gegeven over waarop men op die leeftijd moet letten.",
    "socializationTitle": "Socialisatie",
    "socialization": "We brengen uw puppy in aanraking met verschillende mensen, diersoorten, ondergronden en geluiden. Ook volgt een introductie tot het trimsalon en de onderwaterloopband zodat hij zich ook in deze omstandigheden beter op zijn gemak begint te voelen."
  }
}
</i18n>
