<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('dogFitness')">
    <div class="container py-8">
      <div class="grid grid-cols-1 lg:grid-cols-3 items-start gap-x-10 gap-y-4 lg:gap-y-8">
        <div class="lg:col-span-2">
          <p>{{ $t('content1') }}</p>

          <FadingImages :imgs="[require('@/assets/dog-fitness-18.jpg'), require('@/assets/dog-fitness-19a.jpg'), require('@/assets/dog-fitness-20a.jpg'), require('@/assets/dog-fitness-21a.jpg'), require('@/assets/dog-fitness-2.jpg')]" :alt="$t('altDogExercises')" class="rounded-lg w-full max-w-text h-96 md:h-132 mb-4"></FadingImages>

          <p>{{ $t('content2') }}</p>
          <p>{{ $t('content3') }}</p>
        </div>

        <div class="self-stretch hidden lg:block">
          <Rates class="sticky top-8"></Rates>
        </div>
      </div>
    </div>

    <div class="w-100 overflow-x-hidden mb-8">
      <div class="container-right-text-aligned bg-gray-50 md:shadow-md rounded-l-xl border-b-4 border-beige-light md:flex items-start" data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
        <div class="px-4 md:pr-6 lg:pl-0 pt-8 lg:pt-6 mb-4 md:mb-0">
          <h3 class="text-red">{{ $t('hydrotherapyTitle') }}</h3>
          <p>{{ $t('hydrotherapy') }}</p>
          <localized-link :to="{ path: '/hydrotherapie' }">
            <Button >
              {{ $t('hydrotherapyMore') }}
              <template v-slot:iconRight>
                <ChevronRightIcon/>
              </template>
            </Button>
          </localized-link>
        </div>

        <div class="mt-4 md:mt-0">
          <video playsinline controls autoplay loop muted class="object-cover w-full h-96">
            <source :src="require('@/assets/dog-hydro-14.mp4')" type="video/mp4">
          </video>
        </div>
      </div>
    </div>

    <div class="container lg:hidden pb-8">
      <Rates></Rates>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import FadingImages from '@/components/FadingImages'
import Button from '@/components/Button'

// Define rates in separate component as will need to be used twice in different places depending on screen size.
const Rates = {
  template: `
    <div class="p-4 shadow-md bg-gray-50 rounded-lg">
      <h3 class="text-red">{{ $tc('rate', 2) }}</h3>
      <h4 class="italic mb-2">{{ $t('rateATitle') }}</h4>
      <p class="text-left text-gray-500">{{ $t('rateA1') }}</p>
      <h4 class="italic mb-2">{{ $t('rateBTitle') }}</h4>
      <p class="text-left text-gray-500">{{ $t('rateB1') }}</p>
      <h4 class="italic mb-0">{{ $t('rateCTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateCNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateC1') }}<br>
      </p>
      <h4 class="italic mb-0">{{ $t('rateDTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateDNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateD1') }}
      </p>
    </div>
  `,
  i18n: {
    messages: {
      nl: {
        "rateATitle": "Hondenfitness grondoefeningen",
        "rateA1": "Per sessie (30 min): €\xa035",
        "rateBTitle": "Hondenfitness onderwaterloopband",
        "rateB1": "Per sessie (30 min): €\xa040",
        "rateCTitle": "Combo grond + hydro",
        "rateCNote": "Combinatie van hondenfitness op grond en in de onderwaterloopband.",
        "rateC1": "Per sessie (45 min): €\xa045",
        "rateDTitle": "Eerste sessie",
        "rateDNote": "Tijdens de eerste consultatie nemen we voldoende tijd om het programma te bespreken.",
        "rateD1": "Eerste sessie (45-60 min): €\xa050"
      }
    }
  }
}

export default {
  name: 'DogFitness',
  components: {
    Head,
    ChevronRightIcon,
    BlackWhiteTemplate,
    FadingImages,
    Button,
    Rates
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Hondenfitness - Yn'forma",
      "meta": {
        "description": "Hondenfitness en hydrotherapie ter ontwikkeling van spiermassa en conditie. Gevestigd te Gooik. Zowel voor sporthonden als voor algemene gezondheid.",
        "keywords": "hondenfitness, hydrotherapie, fysiotherapie, gooik, oetingen, yn'forma, ynforma, honden, hond, huisdier, huisdieren, revalidatie"
      }
    },
    "dogFitness": "Hondenfitness",
    "content1": "Net zoals bij de mens zorgt fitness bij uw hond ervoor dat de spieren zich goed ontwikkelen en dat de spiermassa wordt behouden. Dit is van essentieel belang voor sporthonden en honden die op show gaan, maar ook voor het algemeen welzijn en conditie van elke hond. Het is ook de ideale manier om angstige honden te helpen aan meer zelfvertrouwen.",
    "content2": "Tijdens deze lessen leert u hoe u de coördinatie, flexibiliteit en de proprioceptie van uw hond kan trainen. Iedere hond krijgt een individueel trainingsschema, afhankelijk van zijn conditie. Er wordt gebruik gemaakt van verschillende materialen en toestellen, zoals balanskussens, cavaletti’s, de onderwaterloopband\u00a0... Het trainen met verschillende toestellen maakt het voor de hond leuk en leerzaam.",
    "content3": "Voor sporthonden wordt er gekeken naar de meest voorkomende blessures binnen de sporttak, en wordt er getraind op het verstevigen van deze spieren om de kans op blessures te verkleinen.",
    "hydrotherapyTitle": "De onderwaterloopband: een ideale workout!",
    "hydrotherapy": "Op onze onderwaterloopband kan uw hond zijn conditie trainen en zijn spieren ontwikkelen zonder zijn lichaam te belasten: de draagkracht van het water spaart uw viervoeters gewrichten terwijl de extra weerstand voor sneller resultaat zorgt.",
    "hydrotherapyMore": "Meer over de onderwaterloopband",
  }
}
</i18n>
