<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="og:title" :content="title">
    <!-- TODO add other tags -->
  </Head>

  <div class="flex-grow">
    <Loading :show="loading" :label="$t('loadingStatus')"/>

    <div v-if="voucher">
      <div v-if="voucher.status === 'paymentFailed'">
        <h2>{{ $t('paymentCanceledTitle') }}</h2>
        <p>{{ $t('paymentCanceled') }}</p>

        <localized-link :to="{ path: `/cadeaubon` }" class="mt-6">
          <Button>
            {{ $t('tryAgain') }}
            <template v-slot:iconRight>
              <ChevronRightIcon/>
            </template>
          </Button>
        </localized-link>
      </div>

      <div v-else>
        <h2>{{ $t('orderPlacedTitle') }}</h2>
        <p>{{ $t('orderPlaced') }}</p>
        <p>
          {{ $t('code') }}: {{ voucher.code }}<br>
          {{ $t('price') }}: €&nbsp;{{ parseFloat(voucher.price).toFixed(2).replace(/[.,]0+$/, "") }}<br>
        </p>

        <localized-link :to="{ path: `/` }" class="mt-6">
          <Button>
            {{ $t('toHome') }}
            <template v-slot:iconRight>
              <ChevronRightIcon/>
            </template>
          </Button>
        </localized-link>
      </div>
    </div>

    <div v-if="errorMessage" class="text-red">{{ errorMessage }}</div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'
import Loading from '@/components/Loading'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import Button from '@/components/Button'

export default {
  name: 'VoucherProcessed',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    Loading,
    ChevronRightIcon,
    Button
  },
  data () {
    return {
      loading: true,
      retryCounter: 0,
      retryInterval: 2000,
      retryMax: 30,
      voucher: null,
      errorMessage: null,
      title: 'Yn\'forma'
    }
  },
  methods: {
    getVoucher () {
      this.loading = true
      this.$axios.get(`/vouchers?code=${this.$route.query['code']}`)
        .then(res => {
          const voucher = res.data.results[0]

          this.errorMessage = null
          
          if (voucher.status === 'pending') {
            // Payment not completed yet, wait and retry.
            this.retryCounter++
            if (this.retryCounter < this.retryMax) {
              setTimeout(this.getVoucher, this.retryInterval)
            } else {
              this.loading = false
              this.errorMessage = this.$t('stillPending')
            }
          } else {
            this.voucher = voucher
            this.loading = false

            // If paid, clear shopping cart.
            if (voucher.status !== 'paymentFailed') {
              this.title = this.$t('head.titlePlaced')
              this.$store.commit('clearCheckoutDetailsVoucher')
            } else {
              this.title = this.$t('head.titleCanceled')
            }
          }
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    }
  },
  mounted () {
    this.title = this.$t('head.titleLoading')
    this.getVoucher()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "titleLoading": "Betaling nakijken - Yn'forma",
      "titlePlaced": "Uw cadeaubon - Yn'forma",
      "titleCanceled": "Betaling afgebroken - Yn'forma"
    },
    "loadingStatus": "Betaling nakijken",
    "orderPlacedTitle": "Uw cadeaubon is klaar!",
    "orderPlaced": "Wij danken u voor uw bestelling! Hieronder vindt u de code van uw cadeaubon. Deze informatie werd u ook per e-mail opgestuurd.",
    "code": "Code",
    "price": "Waarde",
    "toHome": "Terug naar de startpagina",
    "paymentCanceledTitle": "Betaling afgebroken",
    "paymentCanceled": "Uw betaling werd afgebroken en de bestelling van uw cadeaubon is bijgevolg niet geplaatst. Gelieve opnieuw te proberen of ons rechtstreeks te contacteren om de cadeaubon te kopen.",
    "tryAgain": "Opnieuw proberen",
    "stillPending": "De betalingsstatus is nog niet beschikbaar. Vernieuw deze pagina om opnieuw te proberen of contacteer ons om na te kijken of de bestelling van uw cadeaubon geplaatst is."
  }
}
</i18n>
