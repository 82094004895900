<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <BlackWhiteTemplate :title="$t('services')">
    <div class="container py-8">
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 justify-center">
        <ServiceCard v-for="(service, index) in services" :key="service.title" :title="service.title" :path="service.path" :hash="service.hash" :img="service.img" data-aos="fade-up" data-aos-anchor-placement="top-bottom" :data-aos-anchor="$tailwind.breakpoints.md ? 'html' : ''" :data-aos-delay="$tailwind.breakpoints.md ? 50 * index : 0">
          {{ service.description }}
        </ServiceCard>
      </div>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import ServiceCard from '@/components/ServiceCard.vue'

export default {
  name: 'Services',
  components: {
    Head,
    BlackWhiteTemplate,
    ServiceCard
  },
  data () {
    return {
      services: [{
        title: this.$t('hydrotherapy'),
        description: this.$t('hydrotherapyDescription'),
        path: '/hydrotherapie',
        hash: null,
        img: require('@/assets/dog-hydro-1.jpg')
      }, {
        title: this.$t('physiotherapy'),
        description: this.$t('physiotherapyDescription'),
        path: '/fysiotherapie',
        hash: null,
        img: require('@/assets/dog-fitness-9b.jpg')
      }, {
        title: this.$t('dogFitness'),
        description: this.$t('dogFitnessDescription'),
        path: '/hondenfitness',
        hash: null,
        img: require('@/assets/dog-fitness-3c.jpg')
      // }, {
      //   title: this.$t('puppyTraining'),
      //   description: this.$t('puppyTrainingDescription'),
      //   path: '/puppytraining',
      //   hash: null,
      //   img: require('@/assets/puppy-training-1b.jpg')
      // },  {
      //   title: this.$t('nutrition'),
      //   description: this.$t('nutritionDescription'),
      //   path: '/voeding',
      //   hash: null,
      //   img: require('@/assets/dog-food-1.png')
      }, {
        title: this.$t('acupuncture'),
        description: this.$t('acupunctureDescription'),
        path: '/acupunctuur',
        hash: null,
        img: require('@/assets/dog-acupuncture-1b.jpg')
      }, {
        title: this.$t('groomingSalon'),
        description: this.$t('groomingSalonDescription'),
        path: '/trimsalon',
        hash: null,
        img: require('@/assets/dog-trimming-4b.jpg')
      }]
    }
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Diensten - Yn'forma"
    },
    "services": "Diensten",
    "hydrotherapy": "Hydrotherapie",
    "hydrotherapyDescription": "Hydrotherapie is een vorm van therapie waarbij uw dier zich onbelast voortbeweegt in water. Dat kan bij ons op een speciaal hiervoor ontwikkelde onderwaterloopband. Het biedt hulp bij revalidatie, conditietraining, gecontroleerd bewegen, gangpatroon trainen, gewichtsreductie, spieropbouw\u00a0...",
    "physiotherapy": "Fysiotherapie",
    "physiotherapyDescription": "Behandeling na chirurgie, bij artrose, bij overgewicht, bij pijn, bij groeiproblemen, of als conditietraining. Kan bij ons door middel van hydrotherapie, massage, lasertherapie en oefentherapie.",
    "groomingSalon": "Trimsalon",
    "groomingSalonDescription": "Verzorging aangepast aan de vacht van uw hond. Omvat het knippen van de nagels, kuisen van de oren, borstelen, wassen, drogen en het trimmen van de hond. We bieden ook een gratis puppygewenning aan voor de jonkies.",
    "dogFitness": "Hondenfitness",
    "dogFitnessDescription": "Samen zorgen we voor een goede spierontwikkeling en conditie. Voor sport- en showhonden, maar ook voor het algemeen welzijn en de conditie van iedere hond, en voor het bouwen aan het zelfvertrouwen. Individueel programma aangepast aan uw huisdier.",
    "puppyTraining": "Puppytraining",
    "puppyTrainingDescription": "Met de jongsten werken we aan socialisatie, lichaamsbewustzijn en coördinatie. Er wordt geoefend met aangepaste materialen, en u bouwt hier een sterke band op met uw pup.",
    "nutrition": "Voedingsadvies",
    "nutritionDescription": "Wij helpen we u de beste keuze maken voor uw huisdier tussen het ruime aanbod verschillende soorten voeding zodat uw viervoeter zich zo snel mogelijk terug beter voelt!",
    "acupuncture": "Acupunctuur",
    "acupunctureDescription": "Werkt pijnstillend, stimulerend en ontstekingsremmend. De combinatie van acupunctuur en fysiotherapie leidt dikwijls tot sterkere resultaten en kan tevens helpen bij onrust, allergieën, braken en ouderdomsklachten."
  }
}
</i18n>
