<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
    <!-- TODO add other tags -->
  </Head>

  <div class="container flex justify-between items-end" :class="{ 'hidden': !$tailwind.breakpoints.md && $route.name !== 'productList' }">
    <div class="relative w-full top-10 -mt-8 md:max-w-xl p-4 md:p-6 shadow-md bg-beige-light rounded-lg">
      <h2 class="font-thin text-red">{{ $t('shopHeader') }}</h2>
      <p>{{ $t('shopContent') }}</p>
    </div>
    <img :src="require('@/assets/rabbit-side-2.jpg')" class="hidden md:block h-52" alt="">
  </div>

  <div class="bg-white flex-grow" :class="{ 'pt-10': $tailwind.breakpoints.md || $route.name === 'productList' }">
    <div class="container py-8 flex justify-center">
      <router-view/>
    </div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'

export default {
  name: 'Shop',
  components: {
    Head
  },
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Winkel - Yn'forma"
    },
    "shopHeader": "Winkel",
    "shopContent": "Bij Yn'forma bieden we ook enkele producten aan die dikwijls van nut zijn voor onze klanten. Contacteer ons gerust indien u geïnteresseerd bent in het aanbod.",
  }
}
</i18n>
