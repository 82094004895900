<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
  </Head>

  <BlackWhiteTemplate :title="$t('resetPassword')">
    <div class="container py-8">
      <p>{{ $t('resetPasswordIntro') }}</p>

      <Form @submit="resetPassword" :validation-schema="resetPasswordSchema" v-slot="{ isSubmitting }" v-if="!message">
        <Field name="password" type="password" :label="$t('password')" autocomplete="new-password" class="mb-4"/>
        <Field name="repeatPassword" type="password" :label="$t('repeatPassword')" autocomplete="new-password" class="mb-4"/>

        <div v-show="errorMessage" class="mb-4 text-red flex items-start">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </div>

        <Button type="submit" :disabled="isSubmitting">{{ $t('savePassword') }}</Button>
      </Form>

      <p v-show="message">{{ message }}</p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import Button from '@/components/Button'
import { Form } from 'vee-validate'
import '@/validations'
import * as yup from 'yup'
import Field from '@/components/Field'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'ResetPassword',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    BlackWhiteTemplate,
    Button,
    Field,
    Form,
    ExclamationIcon
  },
  data () {
    return {
      resetPasswordSchema: yup.object({
        password: yup.string()
          .required(this.$t('validation.password.required'))
          .password(this.$tc('validation.password.min', 8),
            this.$tc('validation.password.number', 1),
            this.$tc('validation.password.letter', 1)),
        repeatPassword: yup.string()
          .required(this.$t('validation.repeatPassword.required'))
          .oneOf([yup.ref('password')], this.$t('validation.repeatPassword.matchPassword'))
      }),
      message: null,
      errorMessage: null
    }
  },
  methods: {
    resetPassword (values, actions) {
      return this.$axios.post('/auth/reset-password', {
        token: this.$route.query.token,
        password: values.password
      })
        .then(res => {
          actions.resetForm()
          this.message = this.$t('passwordReset')
          this.errorMessage = null
        })
        .catch(err => {
          this.errorMessage = this.$axiosErrorHandler(err, values, actions)
          this.message = null
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "head": {
      "title": "Password reset - Yn'forma",
    },
    "resetPassword": "Password reset",
    "resetPasswordIntro": "Choose a new password here.",
    "savePassword": "Save new password",
    "password": "New password",
    "repeatPassword": "Repeat your new password",
    "validation": {
      "password": {
        "required": "Please fill in a new password."
      },
      "repeatPassword": {
        "required": "Please repeat your new password."
      }
    },
    "passwordReset": "Your new password has been saved!"
  },
  "nl": {
    "head": {
      "title": "Wachtwoord opnieuw instellen - Yn'forma",
    },
    "resetPassword": "Wachtwoord opnieuw instellen",
    "resetPasswordIntro": "Kies hier een nieuw wachtwoord.",
    "savePassword": "Nieuw wachtwoord opslaan",
    "password": "Nieuw wachtwoord",
    "repeatPassword": "Herhaal uw nieuw wachtwoord",
    "validation": {
      "password": {
        "required": "Vul alstublieft een nieuw wachtwoord in."
      },
      "repeatPassword": {
        "required": "Herhaal alstublieft uw nieuw wachtwoord."
      }
    },
    "passwordReset": "Uw nieuw wachtwoord werd opgeslagen!"
  },
  "fr": {
    "head": {
      "title": "Réinitialisation de mot de passe - Yn'forma",
    },
    "resetPassword": "Réinitialisation de mot de passe",
    "resetPasswordIntro": "Choisissez un nouveau mot de passe ici.",
    "savePassword": "Sauvegarder nouveau mot de passe",
    "password": "Nouveau mot de passe",
    "repeatPassword": "Répétez votre nouveau mot de passe",
    "validation": {
      "password": {
        "required": "Veuillez introduire un nouveau mot de passe."
      },
      "repeatPassword": {
        "required": "Veuillez répéter votre nouveau mot de passe."
      }
    },
    "passwordReset": "Votre nouveau mot de passe a été sauvegardé !"
  }
}
</i18n>
