<template>
  <div v-show="visible" class="flex flex-col items-center">
    <svg viewBox="0 0 50 50" class="animate-spin box-content w-8">
      <circle cx="25" cy="25" r="23" stroke-width="4" fill="none" class="stroke-beige-light"/>
      <path fill="none" stroke-width="4" d="M25,2 a23,23 0 0,1 23,23" stroke-linecap="round" class="stroke-beige-dark"/>
    </svg>
    <div v-if="label" class="mt-2 text-beige-darker">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    show: Boolean,
    showingCallback: Function,
    delay: {
      type: Number,
      default: 500
    },
    label: String
  },
  data () {
    return {
      visible: false,
      visibleTimer: null
    }
  },
  watch: {
    show (value) {
      this.showWithDelay()
    }
  },
  methods: {
    showWithDelay () {
      // Delay showing timer: if visible gets toggled to false within a short time, don't display it at all.
      if (this.show) {
        this.visibleTimer = setTimeout(() => {
          this.visible = true
          if (this.showingCallback) {
            this.showingCallback()
          }
        }, this.delay)
      } else {
        clearTimeout(this.visibleTimer)
        this.visible = false
      }
    }
  },
  mounted () {
    this.showWithDelay()
  }
}
</script>
