<template>
  <div class="flex gap-x-2">
    <button v-if="pages > maxPages" @click="$emit('update:modelValue', 1)" :disabled="modelValue === 1" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'text-gray-500' : modelValue === 1 }">
      <ChevronDoubleLeftIcon class="h-5"/>
    </button>
    <button v-if="pages > 1" @click="$emit('update:modelValue', modelValue - 1)" :disabled="modelValue === 1" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'text-gray-500' : modelValue === 1 }">
      <ChevronLeftIcon class="h-5"/>
    </button>
    <button v-for="page in range" @click="$emit('update:modelValue', page)" :key="page" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'bg-gray-300': page === modelValue }">
      {{page}}
    </button>
    <button v-if="pages > 1" @click="$emit('update:modelValue', modelValue + 1)" :disabled="modelValue === pages" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'text-gray-500' : modelValue === pages }">
      <ChevronRightIcon class="h-5"/>
    </button>
    <button v-if="pages > maxPages" @click="$emit('update:modelValue', pages)" :disabled="modelValue === pages" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'text-gray-500' : modelValue === pages }">
      <ChevronDoubleRightIcon class="h-5"/>
    </button>
  </div>
</template>

<script>
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/vue/outline'

export default {
  name: 'Pagination',
  components: {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon
  },
  props: {
    modelValue: Number,
    pages: Number,
    maxPages: {
      type: Number,
      default: 5
    }
  },
  emits: ['update:modelValue'],
  computed: {
    range () {
      let start = this.modelValue - Math.ceil((this.maxPages - 1) / 2)
      let end = this.modelValue + Math.floor((this.maxPages - 1) / 2)

      if (start <= 0) {
        end = end + 1 - start
      }

      if (end > this.pages) {
        start = start - (end - this.pages)
      }

      start = Math.max(start, 1)
      end = Math.min(end, this.pages)

      const range = []
      for (let i = start; i <= end; i++) {
        range.push(i)
      }

      return range
    }
  }
}
</script>
