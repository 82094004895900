import { createWebHistory } from 'vue-router'
import { createLangRouter } from 'vue-lang-router'

import store from '@/store'
import translations from '@/i18n/translations'
import urls from '@/i18n/urls'

import Info from '@/views/info/Info.vue'
import Home from '@/views/info/Home.vue'
import Services from '@/views/info/Services.vue'
import Hydrotherapy from '@/views/info/Hydrotherapy.vue'
import Physiotherapy from '@/views/info/Physiotherapy.vue'
import GroomingSalon from '@/views/info/GroomingSalon.vue'
import DogFitness from '@/views/info/DogFitness.vue'
import PuppyTraining from '@/views/info/PuppyTraining.vue'
import Nutrition from '@/views/info/Nutrition.vue'
import Acupuncture from '@/views/info/Acupuncture.vue'
import Shop from '@/views/info/shop/Shop.vue'
import ProductList from '@/views/info/shop/ProductList.vue'
import Product from '@/views/info/shop/Product.vue'
import ShoppingCart from '@/views/info/shop/ShoppingCart.vue'
import OrderProcessed from '@/views/info/shop/OrderProcessed.vue'
import Voucher from '@/views/info/shop/Voucher.vue'
import VoucherProcessed from '@/views/info/shop/VoucherProcessed.vue'
import Contact from '@/views/info/Contact.vue'

import Register from '@/views/auth/Register.vue'
import Login from '@/views/auth/Login.vue'
import VerifyEmail from '@/views/auth/VerifyEmail'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'

import NotFound from '@/views/misc/NotFound.vue'
import PrivacyPolicy from '@/views/misc/PrivacyPolicy.vue'
import ShippingReturnPolicy from '@/views/misc/ShippingReturnPolicy.vue'
import Conditions from '@/views/misc/Conditions.vue'

// Navigation guard for authentication.
const auth = ({ authPath, authLocation, notAuthPath, notAuthLocation, verifiedPath, notVerifiedPath, roles = [], rememberDestination = false }) => (to, from, next) => {
  // Check if user has been loaded or if request has not yet been completed.
  if (store.state.currentUser !== undefined) {
    proceed()
  } else {
    store.dispatch('fetchCurrentUser')
      .then(user => {
        if (user !== undefined) {
          proceed()
        }
      })
      // TODO: check whether we need to catch here?
  }

  function proceed () {
    if (store.state.currentUser && (!roles || roles.length === 0 || roles.includes(store.state.currentUser.role))) {
      if (notVerifiedPath && !store.state.currentUser.isEmailVerified) {
        if (rememberDestination) {
          next({ path: notVerifiedPath, query: { destination: to.fullPath } })
        } else {
          next(notVerifiedPath)
        }
      } else if (verifiedPath && store.state.currentUser.isEmailVerified) {
        next(verifiedPath)
      } else if (authLocation) {
        window.location.href = authLocation
      } else if (authPath) {
        next(authPath)
      } else {
        next()
      }
    } else {
      if (notAuthLocation) {
        let redirect = notAuthLocation
        if (rememberDestination) {
          redirect += '?destination=' + to.fullPath
        }
        window.location.href = redirect
      } else if (notAuthPath) {
        if (rememberDestination) {
          next({ path: notAuthPath, query: { destination: to.fullPath } })
        } else {
          next(notAuthPath)
        }
      } else {
        next()
      }
    }
  }
}

// Navigation guard for getting customer.
const getCustomer = () => (to, from, next) => {
  // Check if customer has been loaded or if request has not yet been completed.
  if (store.state.currentCustomer !== undefined) {
    next()
  } else {
    store.dispatch('fetchCurrentCustomer')
      .then(customer => {
        if (customer !== undefined) {
          next()
        }
      })
    // TODO: check whether we need to catch here?
  }
}

const routes = [{
  path: '/',
  component: Info,
  children: [
    {
      path: '',
      component: Home
    }, {
      path: 'diensten',
      component: Services
    }, {
      path: 'hydrotherapie',
      component: Hydrotherapy
    }, {
      path: 'revalidatie',
      redirect: 'fysiotherapie'
    }, {
      path: 'fysiotherapie',
      component: Physiotherapy
    }, {
      path: 'trimsalon',
      component: GroomingSalon
    }, {
      path: 'hondenfitness',
      component: DogFitness
    // }, {
    //   path: 'puppytraining',
    //   component: PuppyTraining
    // }, {
    //   path: 'voeding',
    //   component: Nutrition
    }, {
      path: 'acupunctuur',
      component: Acupuncture
    }, {
      path: 'winkel',
      component: Shop,
      name: 'shop',
      children: [
        {
          path: '',
          component: ProductList,
          name: 'productList'
        }, {
          path: ':productId',
          component: Product,
          name: 'product'
        }, {
          path: '/winkelwagen',
          component: ShoppingCart,
          name: 'shoppingCart'
        }, {
          path: '/bestellen',
          component: ShoppingCart,
          name: 'checkout'
        }, {
          path: '/bestelling-verwerkt',
          component: OrderProcessed,
          name: 'orderProcessed'
        }, {
          path: '/cadeaubon',
          component: Voucher,
          name: 'voucher'
        }, {
          path: '/cadeaubon-verwerkt',
          component: VoucherProcessed,
          name: 'voucherProcessed'
        }
      ]
    }, {
      path: 'contact',
      component: Contact
    }, {
      path: 'registreren',
      component: Register
    }, {
      path: 'inloggen',
      component: Login,
      beforeEnter: [
        auth({ roles: ['user'], authPath: '/klantenportaal' }), // Redirect to profile page if logged in as normal user.
        auth({ roles: ['admin'], authLocation: '/portaal' }) // Redirect to portal page if logged in as admin.
      ]
    }, {
      path: 'emailverificatie',
      component: VerifyEmail
    }, {
      path: 'wachtwoord-vergeten',
      component: ForgotPassword
    }, {
      path: 'wachtwoord-opnieuw-instellen',
      component: ResetPassword
    }, {
      path: ':pathMatch(.*)*',
      redirect: '/404'
    }, {
      path: '/privacyverklaring',
      component: PrivacyPolicy
    }, {
      path: '/verzend-en-retourbeleid',
      component: ShippingReturnPolicy
    }, {
      path: '/algemene-voorwaarden',
      component: Conditions
    }, {
      path: '/404',
      component: NotFound
    }
  ]
},
// User portal
{
  path: '/klantenportaal',
  // Route level code-splitting.
  // This generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "userPortal" */ '@/views/portal/Portal.vue'),
  beforeEnter: [
    auth({ notAuthPath: '/inloggen', rememberDestination: true }), // Redirect to login page if not logged in.
    auth({ roles: ['admin'], authPath: '/404' }), // Redirect to 404 page if admin.
    getCustomer() // Get customer.
  ],
  children: [{
    path: '',
    component: () => import(/* webpackChunkName: "userPortal" */ '@/views/portal/Home.vue'),
    beforeEnter: auth({ notVerifiedPath: '/klantenportaal/verificatie-email-sturen' }), // Redirect to e-mail verification page if not verified yet.
    redirect: '/klantenportaal/profiel',
    children: [{
      path: 'profiel',
      component: () => import(/* webpackChunkName: "userPortal" */ '@/views/portal/Profile.vue')
    }]
  }, {
    path: 'verificatie-email-sturen',
    component: () => import(/* webpackChunkName: "userPortal" */ '@/views/auth/SendVerificationEmail'),
    beforeEnter: auth({ verifiedPath: '/klantenportaal' }) // Redirect to profile page if e-mail is already verified.
  }]
}]

const langRouterOptions = {
  defaultLanguage: 'nl',
  translations: translations,
  localizedURLs: urls,
  i18nOptions: {
    fallbackLocale: 'nl',
    formatFallbackMessages: true,
    silentFallbackWarn: true,
    silentTranslationWarn: true
  }
}

const routerOptions = {
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }

    return { left: 0, top: 0 }
  }
}

const router = createLangRouter(langRouterOptions, routerOptions)

// After navigation to other page, scroll to top so AOS animations don't trigger.
// Otherwise, when we're scrolled down a page and navigate to another one, AOS
// seems to think we've already scrolled past the animated element and triggers
// the animation.
// This workaround is not noticeable and does not conflict with the scrollBehavior
// defined in the router options above.
router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})

export default router
